@import "./root.scss";

.bootstrap-select .dropdown-menu {
    max-height: 250px !important;
}

.dropdown-container_child1.CMDB.active {
    top: 20% !important;
}

.dropdown-container_child1.settings.active {
    top: 58% !important;
}

.dropdown-container_child1.admin.active {
    top: 41% !important;
}

.dropdown-container {
    transition: transform 0.3s cubic-bezier(0.88, -0.72, 0, 1);
    list-style: none;
    margin: 0;
    padding: 0;
    top: 0;
    left: 64px;
    position: absolute;
    background-color: var(--white);
    border-radius: 8px;
    border: 1px solid #18347c;
    /*transition: all 0.8s;*/
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    width: 214px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(2em);
    z-index: -1;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    border-top-left-radius: 3px;
}

.dropdown-container.nodisplay {
    display: none;
}

.dropdown-container_child.active {
    visibility: visible;
    /* shows sub-menu */
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
    backdrop-filter: blur(13px);
    -webkit-backdrop-filter: blur(13px);
}

.dropdown-container_child1.active {
    visibility: visible;
    /* shows sub-menu */
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
    backdrop-filter: blur(13px);
    -webkit-backdrop-filter: blur(13px);
}

.dropdown-container_child {
    display: flex;
    flex-direction: column-reverse;
    /* Reverse the order of the child elements */
    transition: transform 0.3s cubic-bezier(0.88, -0.72, 0, 1);
    list-style: none;
    margin: 0;
    padding: 0;
    left: 214px;
    bottom: -96%;
    /* Keep the container above the heading */
    border: 1px solid #18347c;
    position: absolute;
    background-color: rgb(255, 255, 255);
    backdrop-filter: blur(12.6px);
    border-radius: 8px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    width: 214px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-2em);
    z-index: 1;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    backdrop-filter: blur(6px);
    max-height: 200px;
    /* Set a height limit for scrolling */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.dropdown-container_child1 {
    transition: transform 0.3s cubic-bezier(0.88, -0.72, 0, 1);
    list-style: none;
    margin: 0;
    padding: 0;
    left: 200px;
    top: 33%;
    border: 1px solid #18347c;
    left: 214px;
    position: absolute;
    background-color: rgb(255 255 255);
    backdrop-filter: blur(12.6);
    border-radius: 8px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    width: 214px;
    visibility: hidden;
    /* hides sub-menu */
    opacity: 0;
    transform: translateY(2em);
    z-index: 1;
    /* Ensure the child is below the heading */
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    backdrop-filter: blur(6px);
}

.Dropdown-width {
    width: 200px !important;
    margin-left: 4px;
}

.dropdown-container_child a:hover {
    font-weight: bold;
}

.dropdown-container_child.ops {
    top: 0%;
    left:100%;
}
.dropdown-container_child.admin {
    top: 14%;
}
.dropdown-container_child.Compliance{
   top:25%
}
.dropdown-container_child1.efficiency {
    top: 68%;
}

.dropdown-container_child1 a:hover {
    font-weight: bold;
}

.dropdown-container a:hover {
    font-weight: bold;
}

.drop-down-completed {
    border: 1px solid black;
    font-size: 12px;
}

.dd_picker .bootstrap-select>.dropdown-toggle {
    border: none !important;
    font-weight: bold;
    font-size: 16px;
}

.bootstrap-select .dropdown-menu {
    border: 1px solid var(--primary);
}

/* Make sure the dropdown item takes full width */
.custom-dropdown-menu {
    padding-left: 12px;
}

.licenseinput .dropdown-item {
    font-family: "Montserrat", sans-serif !important;
    font-size: var(--font13);
    font-weight: var(--regular);
    font-style: normal;
}

.licenseinput .addOSList .dropdown-menu {
    height: auto;
}

.dropdown.bootstrap-select input {
    box-shadow: none !important;
}

.dropdown-container_child.CMDB {
    top: -57%;
}

.dropdown-container_child.settings {
    top: -33%;
}

.dropdown-container_child {
    max-height: 180px;
    overflow-y: auto;
}

.dropdown-container a {
    padding: 12px 14px !important;
    position: relative;
    margin: 0 1px !important;
    color: var(--text);
    text-align: left;
    background-color: #ffffff82;
    backdrop-filter: blur(12.6);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    // border-bottom: 1px solid var(--primary-active);
}

.dropdown-container a:last-child {
    border-bottom: 0;
}

.dropdown-container a:hover {
    color: var(--primary);
    background-color:transparent!important;
}

.dropdown-container a:after {
    display: none;
}

/* Specific media queries for dropdown and custom styles */
@media (max-width: 1700px) and (min-width: 0px) {
    .dropdown-container_child1.settings.active {
        top: -11% !important;
        display: flex;
        flex-direction: column-reverse;
    }

    .dropdown-container_child1.settings.active .firstChildNav-heading {
        padding-bottom: 25px;
        border-top: 1px solid #18347c;
        border-radius: 0px;
    }
}

@media (max-width: 1300px) and (min-width: 0px) {
    .dropdown-container_child1.admin.active {
        top: -30% !important;
        display: flex;
        flex-direction: column-reverse;
    }

    .dropdown-container_child1.admin.active .firstChildNav-heading {
        padding-bottom: 25px;
    }
}