@import url("../../assets/css/root.scss");

.detailsFilterStyle {
    min-width: 90px;
    font-size: var(--button-size);
    background-color: var(--button-bg-color) !important;
    color: var(--button-text-color);
    margin-right: 5px;
    height: 38px;
    float: right;
    border: 1px solid var(--button-bg-color);
    &:hover {
        color: var(--button-bg-color);
        background-color: var(--button-text-color) !important;
    }
}

#popupdataserver {
    width: 100%;
    border-collapse: collapse;
}

#popupdataserver td,
#popupdataserver th {
    text-align: center;
    border: 1px solid #ddd;
    padding: 8px;
}

.modalprojectemty {
    background: white !important;
    color: var(--primary) !important;
    text-align: center !important;
    padding: 12px !important;
    height: 50px !important;
    font-size: 13px !important;
    font-weight: bold !important;
    border: 2px solid #F2F4F2 !important;
}

.serverTable thead {
    box-shadow: 0px 0px 0px 2px rgb(229, 229, 229) !important;
}

.search-input {
    color: #595959 !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    border: none !important;
    height: 38px !important;
    background-color: #ECECEC !important;
    border-radius: 12px !important;
    line-height: 18px !important;
}

.search-btn {
    background-color: #ECECEC !important;
    border: none !important;
    border-radius: 12px;
    font-size: 17px !important;
    color: #595959 !important;
}

.custom_table_vul {
    font-size: 13px;
    width: -webkit-fill-available;
    white-space: nowrap;
}

.custom_table_vul thead {
    background-color: #fff;
    z-index: 1;
    box-shadow: 0px -1px 2px 0px rgb(229 229 229);
}

.rectangle {
    width: auto;
    min-width: 30px;
    height: 18px;
    background: #ECECEC;
    border-radius: 3px;
    text-align: center;
    line-height: 18px;
    border: none;
}

.rectangle-Rows {
    width: 47px !important;
    height: 20px !important;
    background: #ECECEC;
    border-radius: 3px;
    text-align: center;
    margin-top: 11px !important;
    border: none;
}

.servertable-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
  
  #popupdataserver server{
    width: 100%;
    border-collapse: collapse;
  }
  
  #popupdataserver thead th {
    position: sticky;
    top: 0;
    background-color: #f4f4f4; /* Header background for visibility */
    z-index: 1;
  }
  
  #popupdataserver tbody {
    display: block;
    max-height: 180px; /* Set your desired max height */
    overflow-y: auto;
    padding: 0%;
  }
  
  #popupdataserver tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* Ensures columns align properly */
  }
  
  #popupdataserver thead, #popupdataserver tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* Align thead and tbody columns */
  }
.displayflex {
    display: flex;
}

/////
.outerCircle {

    margin: 8px 2px;
}

.projectscanupdate {
    color: #28a745;
}

.projectscanpending {
    color: #a8a2a2;

}
.selection-list {
    margin: 20px;
}
.Confirmationtext {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: green !important;
    text-align: left !important;
}
.selection-ProjectValue {
    color: #757575 !important;
}
.checkbox-container {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 10px;
    margin: 13px 2px;
}
.checkbox-container input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
}
.checkbox-container input[type="checkbox"]:checked {
    background-color: #28a745;
    border-color: #28a745;
}
.checkbox-container input[type="checkbox"]::after {
    content: "";
    position: absolute;
    top: 46%;
    left: 50%;
    width: 6px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2.5px 2.5px 0;
    transform: translate(-50%, -50%) rotate(45deg);
}
.projectscanTitle {
    width: 92% !important;
}
.projectscanButton {
    margin: 23px 27px 4px 76px !important;
    justify-content: right !important;
}
.ProjectScanprogress {
  width: 13% !important;
}
.ProjectScanAvailable {
  color: green;
  font-weight: 600;
}
.ProjectScanNotAvailable {
  color: red;
  font-weight: 600;
}
///

  .progressBar{
    height: 10px !important;
  }

  .progress-blocker {
    background-color: #a50303 !important;
    height: 10px !important;
  }
  .progress-red {
    background-color: #91243e !important;
    height: 10px !important;
  }

  .progress-orange {
    background-color: #ff0000 !important;
    height: 10px !important;
  }

  .progress-yellow {
    background-color: #ffa500 !important;
    height: 10px !important;
  }

  .progress-blue {
    background-color: #ffe500 !important;
    height: 10px !important;
  }

  .progressBar-container {
    position: relative;
    width: 100%;
  }

  .progressBar-wrapper {
    position: relative;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .progressBar-wrapper::after {
    content: attr(data-tooltip);
    position: absolute;
    background-color: rgba(0, 0, 0, 0.85);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 10;
  }

  .progressBar-wrapper:hover::after {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(-5px);
  }
  .test_status_open {
    background-color: rgb(231 246 225);
    border: 1px solid rgb(53 176 38);
  }

  .test_status_closed {
    background-color: rgb(244 236 236);
    border: 1px solid rgb(255, 0, 0);
  }

  .test_status_open {
    background-color: rgb(231 246 225);
    border: 1px solid rgb(53 176 38);
  }

  .test_status_closed {
    background-color: rgb(244 236 236);
    border: 1px solid rgb(255, 0, 0);
  }
  .ProjectInfoScroll {
    max-height: 300px !important;
    overflow-y: auto !important;
  }
  .disabled-icon {
    font-size: 20px;
    color: #04235080;
  }
  .InvProjectTable {
    margin: 5px !important;
  }
  .InvProjectTable table tr td {
    padding: 5px 20px !important;
  }
  .InvProjectTableLOader table tr td {
    padding: 2px 0px !important;
  }
  .InvProjectTable .Table-Header {
    margin-bottom: 10px !important;
  }
  .ProjectAdd {
    --bs-btn-line-height: 24.5px !important;
  }
  .test_Repository_closed {
    background-color: rgb(244 236 236);
    border: 1px solid rgb(255, 0, 0);
    border-radius: 6px;
    display: inline-block;
    padding: 0px 10px;
    font-size: 11px;
    font-weight: 600;
  }

  .test_Repository_open {
    background-color: rgb(231 246 225);
    border: 1px solid rgb(53 176 38);
    border-radius: 6px;
    display: inline-block;
    padding: 0px 10px;
    font-size: 11px;
    font-weight: 600;
  }