@charset "UTF-8";

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat');
  font-weight: normal;
}
@import "./root.scss";

* {
  margin: 0;
  padding: 0;
}

/*------------ Rule css -----------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}

h2 {
  font-size: var(--font60) !important;
}

h3 {
  font-size: var(--font20) !important;
}

h4 {
  font-size: var(--font14) !important;
}
.fs-32{
  font-size: 32px;
}
:focus {
  // outline: -webkit-focus-ring-color auto 0;
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none !important;
  -webkit-transition: all .4s linear !important;
  -moz-transition: all .4s linear !important;
  -o-transition: all .4s linear !important;
  -ms-transition: all .4s linear !important;
  transition: all .1s linear !important;
}

a {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.btn-primary {
  color: #fff !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.btn-primary:hover {
  background-color: var(--white) !important;
  color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}


button {
  cursor: pointer;
}

button::-moz-focus-inner {
  outline: 0;
}

ul,
ol {
  padding: 0 !important;
  margin: 0 !important;
}

ul li,
ol li {
  list-style-type: none;
  text-decoration: none;
}

// :focus {
//   outline: 0 !important;
//   box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
// }

::selection {
  background-color: var(--primary);
  color: var(--white);
}
// .row{
//   margin: 0;
//   --bs-gutter-x:0;
// }
/*--------- background class --------*/
.bg-primary {
  background-color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--primary) !important;
}

.bg-white {
  background-color: var(--white) !important;
}

body .bg-light, body .navbar-light {
  background-color: var(--lightbg) !important;
}

/*--------- elements page --------*/

.elementwrap {
  padding: 50px 15px 80px;
}

.elementwrap h2,
.elementwrap .h2 {
  margin: 25px 0;
  display: block;
}

.elementwrap h4,
.elementwrap .h4 {
  margin: 15px 0;
  display: block;
}

.elementwrap .col-md-6 {
  padding-bottom: 30px;
}

.elementwrap .col-md-4,
.elementwrap .col-md-3 {
  padding-bottom: 30px;
}
// [class*="col-"] {
//   padding: 0 5px;
// }


/*-------------------- main buttons -------------------------*/
.solid-btn {
  font-size: var(--font14) !important;
  text-align: center;
  font-weight: var(--regular) !important;
  display: inline-block;
  background-color: var(--primary);
  color: var(--white) !important;
  border-radius: 6px;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  text-transform: capitalize;
  -webkit-transition: all .4s linear;
  -moz-transition: all .4s linear;
  -o-transition: all .4s linear;
  -ms-transition: all .4s linear;
  transition: all .4s linear;
}

.solid-btn:hover {
  background-color: var(--white) !important;
  color: var(--primary) !important;
}

.primary-btn {
  font-size: var(--font16);
   
  font-weight: var(--regular);
  display: inline-block;
  background-color: var(--primary);
  color: var(--white);
  border-radius: 6px;
  padding: 10px 18px;
  border: 1px solid var(--primary);
  -webkit-transition: all .4s linear;
  -moz-transition: all .4s linear;
  -o-transition: all .4s linear;
  -ms-transition: all .4s linear;
  transition: all .4s linear;
}

.primary-btn:hover {
  background-color: var(--white);
  color: var(--primary);
}

.default-btn {
  color: var(--primary);
  border: 1px solid var(--primary);
  font-size: 16px;
  text-transform: capitalize; 
  padding: 10px 18px;
  border-radius: 6px;
  background-color: var(--white);
  -webkit-transition: all .4s linear;
  -moz-transition: all .4s linear;
  -o-transition: all .4s linear;
  -ms-transition: all .4s linear;
  transition: all .4s linear;
}

.default-btn:hover {
  background-color: var(--primary);
  color: var(--white);
}







.outline-btn {
  font-size: var(--font13);
   
  font-weight: var(--bold);
  display: inline-block;
  background-color: var(--primary-light);
  color: var(--primary-active);
  border-radius: 6px;
  padding:8px 16px;
  border: 1px solid var(--primary-active);
  text-transform: capitalize;
  -webkit-transition: all .4s linear;
  -moz-transition: all .4s linear;
  -o-transition: all .4s linear;
  -ms-transition: all .4s linear;
  transition: all .4s linear;
}

.outline-btn:hover {
  background-color: var(--primary-active);
  color: var(--primary-light);
}

.graybtn {
  background-color: #606060;
  border: 1px solid #606060;
}

.btnswrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto 0;
}

.btnswrap .solid-btn {
  margin: 0 5px;
}
.btnswrap_uar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnswrap_uar .solid-btn {
  margin: 0 5px;
}

/*-------------------- form elements -------------------------*/
.frmgroup {
  margin-bottom: 13px;
}

.frmgroup input {
  font-size: var(--font14);
  font-weight: var(--regular) !important;
  color: var(--gray2) !important;
  background-color: var(--lightbg) ;
  width: 100%;
  padding: 20px 24px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid var(--primary-active);
}

.dateinput input {
  font-size: var(--font13);
   
  font-weight: var(--regular);
  font-style: normal;
  color: var(--text);
  border: 1px solid var(--secondary-light);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: var(--white);
  padding: 11px 30px 9px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../images/down-caret.svg);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 15px 12px;
  cursor: pointer;
}
.dateinputflowMetrics input {
  font-size: var(--font13);
   
  font-weight: var(--regular);
  font-style: normal;
  color: var(--text);
  border: 1px solid var(--secondary-light);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: var(--white);
  padding: 11px 30px 6px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../images/down-caret.svg);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 15px 12px;
  cursor: pointer;
}

.default-input label,
.radiogroup label {
  font-size: var(--font13);
   
  font-weight: var(--regular);
  font-style: normal;
  color: #080707;
 // margin-bottom: 6px;
}

.default-input input {
  // font-size: var(--font14);
  height: 15px;
  font-weight: var(--regular);
  font-style: normal;
  color: var(--gray3);
  // border: 1px solid var(--border);
  font-size: var(--content-size);
  padding: 19px 7px 17px 7px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: var(--white);
  // padding: 15px 0px 19px 7px;
}
.default-inputs input{
    font-weight: var(--regular);
    font-style: normal;
    color: var(--gray3);
    font-size: var(--content-size);
    padding: 19px 33px 17px 14px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    background-color: var(--white);
    border-radius: 20px;
    border-width: 2px;
    margin-left: 10px;
    height:0px;
}
.default-input input:focus{
  background-color: #f2f4f2;
  border: 1px solid var(--secondary-light);
}

/*--------- radio --------*/
.form-check-input[type="radio"] {
  width: 20px;
  height: 20px;
  border: 2px solid var(--black);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 0;
}

.form-check-input:checked[type="radio"] {
  // background-image: url(../images/radio-2.svg);
  background-color: transparent;
  background-size: 8px 8px;
  border-color: var(--black);
}

/*--------- checkbox --------*/
.form-check-input[type="checkbox"] {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid var(--black);
}

.form-check-input:checked[type="checkbox"] {
  background-color: #fff;
  background-size: 10px 8px;
  border-color: var(--black);
}

/*--------- checkbox & radio label --------*/
.form-check-label {
  font-size: var(--font14);
  font-weight: var(--regular);
  font-style: normal;
  color: var(--text);
  margin-left: 8px;
  cursor: pointer;
  margin-top: 2px;
}

/*--------- default select --------*/
.form-select {
  font-size: var(--font14);
   
  font-weight: var(--regular);
  font-style: normal;
  color: var(--text);
  border: 1px solid var(--secondary-light) !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: #fff;
  padding: 10px !important;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  --webkit-appearance: none;
  --moz-appearance: none;
  background-image: url(../images/down-caret.svg);
  background-repeat: no-repeat;
  background-position: right 0.45rem center;
  background-size: 12px 12px;
}

.frmgroup .form-check-input[type="radio"] {
  width: 20px;
  height: 20px;
  border: 1px solid var(--secondary-light);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 0;
  background-color: var(--white);
}

.frmgroup .form-check-input:checked[type="radio"] {
  background-image: url(../images/radio-2.svg);
  background-color: var(--white);
  background-size: 8px 8px;
  border-color: var(--secondary-light);
}


.radiogroup .form-check-inline {
  display: inline-flex;
  margin-right: 1rem;
  align-items: center;
}

.radiogroup .form-check-inline label {
  margin-bottom: 0;
}

/*------------------------------- bootstrap-select css --------------------------------------------------*/

/*!
 * Bootstrap-select v1.14.0-beta2 (https://developer.snapappointments.com/bootstrap-select)
 *
 * Copyright 2012-2021 SnapAppointments, LLC
 * Licensed under MIT (https://github.com/snapappointments/bootstrap-select/blob/master/LICENSE)
 */

@-webkit-keyframes bs-notify-fadeOut {
  0% {
    opacity: 0.9;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes bs-notify-fadeOut {
  0% {
    opacity: 0.9;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bs-notify-fadeOut {
  0% {
    opacity: 0.9;
  }

  100% {
    opacity: 0;
  }
}

select.bs-select-hidden,
.bootstrap-select>select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}

.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/
  vertical-align: middle;
}

.bootstrap-select>.dropdown-toggle {
  position: relative;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.bootstrap-select>.dropdown-toggle:after {
  margin-top: -1px;
}

.bootstrap-select>.dropdown-toggle.bs-placeholder,
.bootstrap-select>.dropdown-toggle.bs-placeholder:hover,
.bootstrap-select>.dropdown-toggle.bs-placeholder:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder:active {
  color: #999;
}

.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-primary,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-secondary,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-success,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-danger,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-info,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-dark,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-primary:hover,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-secondary:hover,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-success:hover,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-danger:hover,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-info:hover,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-dark:hover,
// .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-primary:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-secondary:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-success:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-danger:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-info:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-dark:focus,
// .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-primary:active,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-secondary:active,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-success:active,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-danger:active,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-info:active,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-dark:active {
  color: rgba(255, 255, 255, 0.5);
}

.bootstrap-select>select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
  z-index: 0 !important;
}

.bootstrap-select>select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2 !important;
}

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle,
.bootstrap-select.is-invalid .dropdown-toggle,
.was-validated .bootstrap-select select:invalid+.dropdown-toggle {
  border-color: #b94a48;
}

.bootstrap-select.is-valid .dropdown-toggle,
.was-validated .bootstrap-select select:valid+.dropdown-toggle {
  border-color: #28a745;
}

.bootstrap-select.fit-width {
  width: auto !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px;
}

.bootstrap-select>select.mobile-device:focus+.dropdown-toggle,
.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
  height: auto;
}

:not(.input-group)>.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%;
}

.bootstrap-select.form-control.input-group-btn {
  float: none;
  z-index: auto;
}

.form-inline .bootstrap-select,
.form-inline .bootstrap-select.form-control:not([class*="col-"]) {
  width: auto;
}

.bootstrap-select:not(.input-group-btn),
.bootstrap-select[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0;
}

.bootstrap-select.dropdown-menu-right,
.bootstrap-select[class*="col-"].dropdown-menu-right,
.row .bootstrap-select[class*="col-"].dropdown-menu-right {
  float: right;
}

.form-inline .bootstrap-select,
.form-horizontal .bootstrap-select,
.form-group .bootstrap-select {
  margin-bottom: 0;
}

.form-group-lg .bootstrap-select.form-control,
.form-group-sm .bootstrap-select.form-control {
  padding: 0;
}

.form-group-lg .bootstrap-select.form-control .dropdown-toggle,
.form-group-sm .bootstrap-select.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  border-radius: inherit;
}

.bootstrap-select.form-control-sm .dropdown-toggle,
.bootstrap-select.form-control-lg .dropdown-toggle {
  font-size: inherit;
  border-radius: inherit;
}

.bootstrap-select.form-control-sm .dropdown-toggle {
  padding: 0.25rem 0.5rem;
}

.bootstrap-select.form-control-lg .dropdown-toggle {
  padding: 0.5rem 1rem;
}

.form-inline .bootstrap-select .form-control {
  width: 100%;
}

.bootstrap-select.disabled,
.bootstrap-select>.disabled {
  cursor: not-allowed;
}

.bootstrap-select.disabled:focus,
.bootstrap-select>.disabled:focus {
  outline: none !important;
}

.bootstrap-select.bs-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 0 !important;
  padding: 0 !important;
}

.bootstrap-select.bs-container .dropdown-menu {
  z-index: 1060;
}

.bootstrap-select .dropdown-toggle .filter-option {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.bs3.bootstrap-select .dropdown-toggle .filter-option {
  padding-right: inherit;
}

.input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option {
  position: absolute;
  padding-top: inherit;
  padding-bottom: inherit;
  padding-left: inherit;
  float: none;
}

.input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option .filter-option-inner {
  padding-right: inherit;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden;
}

.bootstrap-select .dropdown-toggle .filter-expand {
  width: 0 !important;
  float: left;
  opacity: 0 !important;
  overflow: hidden;
}

.bootstrap-select .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle;
}

.bootstrap-select .dropdown-toggle .bs-select-clear-selected {
  position: relative;
  display: block;
  margin-right: 5px;
  text-align: center;
}

.bs3.bootstrap-select .dropdown-toggle .bs-select-clear-selected {
  padding-right: inherit;
}

.bootstrap-select .dropdown-toggle .bs-select-clear-selected span {
  position: relative;
  top: -webkit-calc(((-1em / 1.5) + 1ex) / 2);
  top: calc(((-1em / 1.5) + 1ex) / 2);
  pointer-events: none;
}

.bs3.bootstrap-select .dropdown-toggle .bs-select-clear-selected span {
  top: auto;
}

.bootstrap-select .dropdown-toggle.bs-placeholder .bs-select-clear-selected {
  display: none;
}

.input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit;
}

.bootstrap-select[class*="col-"] .dropdown-toggle {
  width: 100%;
}

.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bootstrap-select .dropdown-menu>.inner:focus {
  outline: none !important;
}

.bootstrap-select .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bootstrap-select .dropdown-menu li {
  position: relative;
}

.bootstrap-select .dropdown-menu li.active small {
  color: rgba(255, 255, 255, 0.5) !important;
}

.bootstrap-select .dropdown-menu li.disabled a {
  cursor: not-allowed;
}

.bootstrap-select .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bootstrap-select .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em;
}

.bootstrap-select .dropdown-menu li a span.check-mark {
  display: none;
}

.bootstrap-select .dropdown-menu li a span.text {
  display: inline-block;
}

.bootstrap-select .dropdown-menu li small {
  padding-left: 0.5em;
}

.bootstrap-select .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bootstrap-select .dropdown-menu .notify.fadeOut {
  -webkit-animation: 300ms linear 750ms forwards bs-notify-fadeOut;
  -o-animation: 300ms linear 750ms forwards bs-notify-fadeOut;
  animation: 300ms linear 750ms forwards bs-notify-fadeOut;
}

.bootstrap-select .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap;
}

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
  position: static;
  display: inline;
  padding: 0;
}

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner,
.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
  display: inline;
}

.bootstrap-select.fit-width .dropdown-toggle .bs-caret:before {
  content: '\00a0';
}

.bootstrap-select.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px;
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 5px;
}

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}

.bootstrap-select .bs-ok-default:after {
  content: '';
  display: block;
  width: 0.5em;
  height: 1em;
  border-style: solid;
  border-width: 0 0.26em 0.26em 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bootstrap-select.show-menu-arrow.open>.dropdown-toggle,
.bootstrap-select.show-menu-arrow.show>.dropdown-toggle {
  z-index: 1061;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
  bottom: auto;
  top: -4px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
  bottom: auto;
  top: -4px;
  border-top: 6px solid white;
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
  right: 12px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
  right: 13px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.open>.dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.show>.dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.open>.dropdown-toggle .filter-option:after,
.bootstrap-select.show-menu-arrow.show>.dropdown-toggle .filter-option:after {
  display: block;
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px;
}

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bs-actionsbox .btn-group {
  display: block;
}

.bs-actionsbox .btn-group button {
  width: 50%;
}

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bs-donebutton .btn-group {
  display: block;
}

.bs-donebutton .btn-group button {
  width: 100%;
}

.bs-searchbox+.bs-actionsbox {
  padding: 0 8px 4px;
}

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none;
}

/*# sourceMappingURL=bootstrap-select.css.map */


.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.bootstrap-select>select.mobile-device:focus+.dropdown-toggle,
.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 0px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}

.bootstrap-select>.dropdown-toggle.bs-placeholder,
.bootstrap-select>.dropdown-toggle.bs-placeholder:hover,
.bootstrap-select>.dropdown-toggle.bs-placeholder:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder:active {
  color: #000;
}

.modal .bootstrap-select>.dropdown-toggle {
  font-style: italic;
  font-weight: 300;
}

.bootstrap-select>.dropdown-toggle {
  font-size: var(--font14);
   
  font-weight: var(--regular);
  font-style: normal;
  color: var(--text);
  border: 1px solid var(--secondary-light);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: var(--white);
  padding: 10px;
  width: 100%;
  font-style: normal;
  height: auto;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.dropdown-toggle::after {
  background-image: url(../images/down-caret.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 12px;
  border: 0;
  width: 14px;
  height: 7px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: inherit;
}

.dropup .dropdown-toggle::after {
  border: 0;
  background-image: url(../images/down-caret.svg);
}

.bootstrap-select .dropdown-menu {
  background-color: #fff;
  border: 1px solid #F0F0F0;
  border-radius: 0 0 8px 8px;
  padding: 0;
  min-width: 95%;
}

.bootstrap-select .dropdown-menu li a.dropdown-item {
  padding: 12px 22px;
  text-indent: 0px;
  font-size: 15px;
   
  color: #000;
  -moz-transition: all 0.15s ease-in;
  -o-transition: all 0.15s ease-in;
  -webkit-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
  margin-bottom: 2px;
}

.bootstrap-select .dropdown-menu li a.dropdown-item:hover,
.bootstrap-select .dropdown-menu li a.dropdown-item:focus {
  color: #000;
  background: var(--secondary-light);
}

.bootstrap-select .dropdown-menu li a.dropdown-item.active,
.bootstrap-select .dropdown-menu li a.dropdown-item:active {
  color: #000;
  background: var(--secondary-light);
}

.bootstrap-select .dropdown-menu li a.dropdown-item.disabled,
.bootstrap-select .dropdown-menu li a.dropdown-item:disabled {
  color: #a0a0a0 !important;
}

.bootstrap-select.multiselect .dropdown-toggle {
  font-size: var(--font14);
   
  font-weight: var(--regular);
  font-style: normal;
  color: var(--text);
  border: 1px solid var(--secondary-light);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: var(--white);
  padding: 10px;
  width: 100%;
  font-style: normal;
  height: auto;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.bootstrap-select .dropdown-menu li a.dropdown-item.selected {
  background: rgba(208, 229, 253, 0.43);
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  display: none;
}

.bootstrap-select>.dropdown-toggle:after {
  margin-top: 2px;
}

.bs-searchbox input {
  font-size: var(--font14);
   
  font-weight: var(--regular);
  color: var(--gray2);
  background-color: var(--lightbg);
  width: 100%;
  padding: 8px 15px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid var(--secondary-light);
}

.form-control:focus {
  // color: var(--gray2);
  background-color: #fff;
  border-color: var(--secondary-light);
}

.chartHolder svg {
  cursor: default !important;
}

path, circle, rect, line {
  cursor: pointer;
}


