.alert {
    width: 100%;
    max-width: 500px;
    position: fixed !important;
    right: 0;
    margin: 0 auto;
    top: 15px;
    left: 0 !important;
    z-index: 999999 !important;
    display: flex;
    border-left: 5px solid !important;
    animation: popup 0.7s;
    // transition: all 5s ease-in-out;
  }
  
  .alert-success {
    color: #0f5132 !important;
    background-color: #f9fff8 !important;
    border-color: #0f5132 !important;
  }
  
  .alert-warning {
    color: #664d03 !important;
    background-color: #fffdf5 !important;
    border-color: #664d03 !important
  }
  
  .alert-error {
    color: #842029 !important;
    background-color: #f6f5f5 !important;
    border-color: #842029 !important
  }
  
  .alert_img {
    flex-basis: 10%;
    padding: 0;
    display: grid;
  }
  
  .alert_msg_contain {
    flex-basis: 80%;
    padding: 0;
  }
  
  .alert strong {
    align-self: center;
  }
  
  .alert .btn-close {
    display: none;
  }

  
.alertbox .btn-close {
  box-sizing: content-box;
  width: 35px;
  height: 40px;
  padding: 0px 10px;
  color: var(--primary) !important;
  background: none;
  border: 0;
  border-radius: 0px;
  opacity: 1;
  position: absolute;
  left: auto;
  right: 0;
  background-color: #fff;
  font-size: 32px;
  font-weight: 200;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.alertbox .top-icon {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 28%) !important;
  margin: 0 auto 30px;
}

.alertbox .top-icon svg {
  width: 20px;
}

.alertbox .modal-body {
  padding: 20px 20px 40px;
}

.alertbox .modal-body p {
  font-size: 18px;
  font-weight: 400;
  color: #030303;
  text-align: center;
}

.alertbox .modal-body .frmgroup label {
  text-align: left;
  font-weight: 500;
  margin-bottom: 8px;
}

.alertbox .modal-body h4 {
  text-align: left;
  margin-bottom: 40px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary);
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.alertbox .frmgroup.default-input {
  width: 85%;
  margin: 0 auto;
}

.alert strong {
  margin-right: 20px;
}