.more-field {
  margin-top: -8px;
  margin-left: -22px;
}

.checkbox-label{
    cursor: pointer;
    font-size: 13px;
    font-family: "Montserrat", sans-serif;
    display: flex;
    align-items: center;
    line-height: 1.6;
    font-weight: normal !important;
  }

  .checkbox-group .dropdown {
    display: contents;
    width: 200px;
    flex-direction: column;
  }

//   .checkbox-group .dropdown-menu{
//     background-color: var(--dark-theme-chart-bg-color) !important;
//     color: var(--dark-theme-text-color) !important;
//   }
  .checkbox-group .dropdown-menu .maxscroll {
    max-height: 250px;
    overflow-y: auto;
  }
  
  .checkbox-group .dropdown-menu .maxscroll::-webkit-scrollbar {
    width: 6px;
  }
  
  .checkbox-group .dropdown-menu .maxscroll::-webkit-scrollbar-thumb {
    background-color: #888888;
  }
  
  .checkbox-group .dropdown-menu .maxscroll::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }
  
  .checkbox-group .dropdown-toggle{
    font-family: "Montserrat", sans-serif !important;
    font-weight: 400 !important;
    font-size: var(--font13) !important;
    height: 38px;
  }

  .checkbox-group .dropdown-toggle::after {
    vertical-align: 0% !important;
  }
  
  .checkbox-input {
    margin-right: 12px;
  }

  .checkbox-label:hover {
    background-color:rgba(177, 188, 147, 0.56);
    cursor: pointer;
  }

  .search-input{
    font-family: "Montserrat", sans-serif;
    height: 26px;
    width: 100%;
    font-size: 12px;
    border: 1px solid var(--primary)
  }
  .no-results{
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    margin-left: 10px;
  }
  .checkboxpad{
    padding: 10px;
  }
.leftserc{
  float: right;
  position: absolute;
}

.selectAllMore {
    font-family: "Montserrat", sans-serif;
    font-size: 12px !important;
    cursor: pointer;
  }
  .selectA{
    border: unset;
  }

.selectbutton .dropdown-toggle {
    background-color: var(--primary) !important;
    &:hover {
        color: var(--button-bg-color) !important;
        background-color: var(--dark-theme-selected-opt) !important;
      }
}