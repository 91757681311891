.pagelink {
  background-color: #fff;
  font-size: var(--font14);

  font-weight: var(--medium);
  font-style: normal;
  color: var(--text);
  margin: 0 8px;
  border: 1px solid var(--secondary-light);
  padding: 6px 10px;
  border-radius: 4px;
}

.pagelink:hover {
  background-color: var(--secondary-light);
  color: #3F5010;
}

.pagelink {
  background-color: #fff;
  font-size: var(--font14);

  font-weight: var(--medium);
  font-style: normal;
  color: var(--text);
  margin: 0 8px;
  border: 1px solid var(--secondary-light);
  padding: 6px 10px;
  border-radius: 4px;
}

.pagelink:hover {
  background-color: var(--secondary-light);
  color: #3F5010;
}

.drilldownThree_backdrop {
  z-index: 1045 !important;
}

.pagination_rowPerPage {
  color: var(--primary);
  // font-size: 1rem;
  font-size: 0.8rem;
}

.table-container {
  max-height: 180px !important;
  overflow-y: auto !important;
}

.table-container::-webkit-scrollbar {
  width: 12px;
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-container::-webkit-scrollbar-thumb {
  background: #888;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.emptyDiv {
  border-bottom: 0px !important;
}

.offcanvas.offcanvas-end.custom-off-canvas {
  width: 90%;
}

.tableheaders_scroll {
  position: sticky;
  top: -1px;
  background-color: white;
  z-index: 100;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.clearBtnStyle {
  background-color: transparent !important;
  border: 1px solid var(--primary);
  color: var(--primary) !important;

  &:hover {
    background-color: var(--primary) !important;
    color: #fff !important;
  }

  &:disabled {
    border-color: grey;
    color: grey;
  }
}

.selectedBtnfilter {
  justify-content: space-between;
  display: flex;
}