@import "./root.scss";

[class*="col-"] {
  padding: 0px 5px !important;
}

.col {
  padding: 0px 5px !important;
}

p {
  font-size: var(--font13);
}

.loader {
  position: absolute;
  width: 100%;

  height: 100%;
  background: #f0f8ffbd;
  display: none;
  z-index: 30;
}

.loader img {
  width: 25%;
  align-self: center;
  position: absolute;
  left: 35%;
}

.graphblock.active .loader {
  display: grid;
}

.table_icon {
  text-align: center;
  position: relative;
}

.table_icon a {
  position: relative;
  font-size: 14px;
}

.m-0 {
  margin: 0;
}

.m-30-0 {
  margin: 30px 0px !important;
}


.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #f6a821;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#updatehead {
  display: none;
}

.upload_btn {
  background-color: #b1bd8b !important;
}

.upload_file {
  padding: 0.375rem 0.75rem !important;
  padding-left: initial !important;
}

.upload_example {
  padding: 0.375rem 0.75rem;
}

.upload_example a {
  color: var(--primary) !important;
}

.detail_icon {
  width: 20px;
}

input[disabled] {
  background-color: #e9ecef !important;
  opacity: 1;
}

select[disabled] {
  background-color: #e9ecef !important;
  opacity: 1;
}

textarea {
  min-height: 6em;
  max-height: 12vw;
  width: 100%;
  background: #f2f4f2;
  resize: none !important;
  font-size: var(--font13);
  padding: 16px;
  border: none;
  border-radius: 0px;
  min-width: 100px;
}

.internal_label {
  color: #030303;
  font-size: 16px;
}

#licenceStr {
  border: 2px solid var(--primary);
  margin-top: 10px;
}

@keyframes popup {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.mr-5 {
  margin-right: 5px !important;
}

.accordion-body .intype {
  width: 145%;
}

.fw-20 {
  flex-basis: 20%;
  word-break: break-word;
  margin: 0 !important;
}

.graph-des-nav ul li {
  padding-right: 6px !important;
}

.flowbox h5 {
  font-size: 25px;
}

.flowacti-block .active h4,
.flowacti-block .active .flowlabel,
.flowacti-block .active .flownum,
.flowacti-block .active .numlabel,
.flowacti-block .active .numdes,
.flowacti-block .active h2,
.flowacti-block .active h5 {
  color: var(--white) !important;
}

.flowacti-block .active .flownum-ftr {
  background-color: rgba(3, 3, 3, 0.12);
}

.x_axis_legend {
  font-size: 12px;
}

.graph-des-nav li span.dark-orange {
  background-color: #ff6f00;
}

.stories-list h5 {
  text-transform: capitalize;
}

.donut .labels {
  font-size: 10px !important;
}

.flowbox.disabled {
  pointer-events: none;
  border: 1px solid #fff;
}

.fw-10 {
  flex-basis: 10%;
}

.fw-20 {
  flex-basis: 20%;
}

.fw-50 {
  flex-basis: 50%;
}

.w-5p {
  width: 5%;
}

.w-20p {
  width: 20%;
}

.center {
  display: flex;
  justify-content: center;
}

.stories-list ol li {
  padding: 15px 0px 15px 4.5% !important;
}

.summary_header div {
  font-weight: bold;
  font-size: 15px;
}

.summary_header th {
  font-weight: bold;
  font-size: 15px;
}

.form-check-input:checked[type="checkbox"] {
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: center;
}

.btnswrap.btn-snd {
  justify-content: right;
  // margin: 27px;
}

.btnswrap.btn-dlt {
  justify-content: center;
  margin: 27px;
}

.myRequest_detail_head.row span {
  font-size: 16px;
  color: #4e4e4e;
  font-weight: 700;
}

.myRequest_detail_head.row span.data_part {
  color: var(--primary);
  margin-left: 10px;
}

.myRequest_detail_head .col-6 {
  margin-bottom: 10px;
}

.accessmenber_name {
  font-size: 13px;
  background: #ffffff;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid;
  border-radius: 3px;
}

.mtlable {
  font-size: 13px;
}

.adpanel_show {
  background: #f4f4f4;
  border: 1px solid #79d2de;
  border-radius: 4px;
  padding: 10px;
  height: 100%;
}

.adpanel_show .header {
  font-weight: 800;
}

.mtlable.add {
  color: #24c616;
}

.mtlable.remove {
  color: #ff3463;
}

.myRequest_detail_head {
  margin-bottom: 10px;
  border-bottom: 1px solid;
}

.hovertipgnc {
  position: relative;
}

.hovertipgnc:before {
  background: #ece2ff;
  border-radius: 4px;
  color: var(--primary);
  border: 1px solid var(--primary);
  content: attr(data-tip);
  font-size: 14px;
  font-weight: 400;
  padding: 6px 10px;
  width: max-content;
  position: absolute;
  z-index: 10;
  left: -335%;
  bottom: 60px;
  font-family: sans-serif;
  transition: all 0.3s linear;
}

.hovertipgnc:hover:before {
  left: 5px;
}

.spin_overlay {
  background: #ffffff;
  z-index: 998;
  overflow: hidden;
  height: 100%;
  padding: 50px 20px;
  position: relative;
  display: grid;
  justify-items: center;
}

.trsSeverity {
  background: #ffffff;
  z-index: 998;
  overflow: hidden;
  height: 82%;
  padding: 105px 20px;
  position: relative;
  display: grid;
  justify-items: center;
}

#myreqbtn {
  position: relative;
  top: -5px;
}

#action {
  position: relative;
  left: -9.5%;
}

.actnav {
  grid-template-columns: auto;
}

.tick line {
  visibility: hidden;
}

.flowacti-block .dark-Orange.active {
  background-color: #ff8000;
  border: 1px solid #ff8000;
}

.dark-Orange {
  border: 1px solid #ff8000;
}

.flowacti-block .Green.active {
  background-color: #81A71A;
  border: 1px solid #81A71A;
}

.Green {
  border: 1px solid #81A71A;
}

.flowacti-block .yellow-1.active {
  background-color: #fec82f;
  border: 1px solid #fec82f;
}

.yellow-1 {
  border: 1px solid #fec82f;
}

.flowacti-block .light-Orange.active {
  background-color: #fda26b;
  border: 1px solid #fda26b;
}

.light-Orange {
  border: 1px solid #fda26b;
}

.ptxt {
  margin: -9px;
}

.graphblock .doraDivImg {
  width: 30px;
  height: 30px;
}

.label {
  font-size: 32px;
  font-weight: 300;
}

.label2,
.label3 {
  font-size: 12px;
  font-weight: 300;
}

.p-0-10 {
  padding: 0 10px;
}

.p-0-5 {
  padding: 0 5px !important;
  padding-right: 30vm;
}

.piiFltrConntain {
  display: flex;
}

.piiChartFltr {
  width: 150px;
  margin-left: auto;
}

#AAtable {
  border-collapse: collapse;
  width: 100%;
  //  height: 350px;
  position: relative;
  font-size: small;
  white-space: nowrap;

}

#AAtable td,
#AAtable th {
  border: 1px solid #ddd;
  padding: 8px;
}

#AAtable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #2c7bcd;
  color: white;
  position: sticky;
  top: -1px;
}

.ActivityChartFltr {
  margin-left: auto;
  width: 190px;
}

.highlighted {
  background-color: #d5e1f2;
  height: 161px;
  border: 1px solid;
}

.UserTrendAlert {
  position: relative;
  height: 50px;
  background: #49e22d;
  color: white;
  padding: 14px;
  animation: popup 0.7s;
  transition: all 5s ease-in-out;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.tableTitle {
  font-weight: var(--bold);
  text-transform: capitalize;
  margin-bottom: 12px;
  text-align: left;
}

.projectemty {
  background: white !important;
  color: var(--primary) !important;
  text-align: left !important;
  position: relative !important;
  text-align: center !important;
  padding: 10px 20px;
  font-size: 13px !important;
  font-weight: bold !important;
  border: 2px solid #F2F4F2 !important;
}

/*-----------------------------------------admin details css------------------*/

#container {
  width: 145px;
  height: 100px;
  border: 1px solid #ccc;
  overflow-x: scroll;
}

#content {
  width: 250px;
  background-color: #ccc;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: auto;
}

.pm-0 {
  padding: 0 !important;
  margin: 0 !important;
}

#outsider {
  height: 25px;
  width: 100%;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  display: inline-block;
}

div#insider {
  position: absolute;
  display: flex;
  height: auto;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  top: 50% !important;
  transform: translateY(-50%);
}

#button-left,
#button-right {
  cursor: pointer;
}

.topassigneeTableTittle {
  font-size: 16px;
  color: #000;
  font-weight: var(--bold);
  margin-top: 20px;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  50% {
    background: rgb(255, 255, 255);
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.frmhead label {
  font-size: 13px;
}

.offcanvas.offcanvas-end.custom-off-canvas.drilldownThree {
  width: 80%;
  padding: 10px;
}

.offcanvas.offcanvas-end.custom-off-canvas.drilldownLast {
  width: 70%;
  padding: 10px;
}

.drilldownThree {
  font-weight: 500;
}

.clickable_Id a{
  cursor: pointer;
  text-decoration: underline !important;
  color: #0d6efd !important;
}
.clickable_Id{
  cursor: pointer;
  text-decoration: underline !important;
  color: #0d6efd !important;
}

.actionDescription {
  font-size: 18px !important;
}

.detailpopup {
  padding: 40px;
  display: grid;
  justify-items: center;
}

.vulnerabilityFontSize {
  font-size: 15px !important;
  margin: 29px 4px 6px 0px;
}

.approveModel p {
  font-size: 15px !important;
}

.actright {
  justify-content: flex-start;
}

.padding25 {
  padding: 25px !important;
}

.LocIssueTittle {
  font-size: var(--font20) !important;
  color: var(--primary);
  font-weight: var(--extrabold);
  margin-bottom: 30px !important;
}

.check {
  display: flex;
  flex-direction: column;
  flex-direction: row;
  justify-content: space-between;
}

#checkBoxFixed {
  position: sticky;
  left: 0;
  background: rgb(220, 223, 221);
}

svg#SvgjsSvg1131 {
  height: 350px !important;
}

.custom-select-server {
  margin-bottom: 27px !important;
}

.custom-select-server {
  margin-bottom: 50px;
}

.server-btn {
  text-align: end;
}

.actionsBtn a {
  padding: 0px;
  flex-basis: 50%;
}

.VMCanvasFilter {
  .custom_scroll {
    height: 72vh;
    padding-right: 5px;
    overflow-x: hidden !important
  }

  .filterOptsStyle {

    // margin-bottom: 30px;
    h3 {
      font-weight: bold;
    }
  }
}

.canvasFilters {
  margin-top: 15px;

  p {
    font-weight: 600;
    margin-bottom: 3px;
    font-size: 14px;
  }

}

.input-wrapper {
  position: relative;
}

.business-add {
  /* Add any additional styling you want for the input field */
  position: relative;
}

.remove-button-business {
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.input-warning{
  font-size: 16px;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.businessAlign {
  top: 158px !important;
}

.CloudAlign {
  top: 230px !important;
}

.serverAlign {
  top: 247px !important;
}
.userAlign {
  top: 270px !important;
}
.userinfoAlign{
  top: 247px !important;
}

.formColUser {
  height: auto;
  width: 0;
  position: absolute;
  z-index: 1;
  top: 269px;
  right: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.formCol {
  height: auto;
  width: 0;
  position: absolute;
  z-index: 1;
  top: 165px;
  right: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

#tableCol {
  transition: 0.5s;
  padding: 16px;
}

.capitalize {
  text-transform: capitalize;
}

.tdico {
  color: var(--primary);
}

#PluginClick {
  cursor: pointer !important;
  color: #0d6efd !important;
  text-decoration: underline !important;
}

#PluginClick:hover {
  text-decoration: none !important;
  transition: all 0.4s linear !important;
}

.incidintfont {
  font-size: 13px !important;
}

.tableRedHighlight {
  color: red;
}

.btn-group {
  margin-left: 1px !important;
}

.downloadAcounts {
  margin-right: 20px;
}

.centerTransform {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.severity-badge {
  display: inline-block;
  width: 85px;
  border-radius: 3px;
  text-align: center;
}

.severity-badge_critical {
  background-color: #ff7575;
  border: solid 1px #f00;
}

.severity-badge_low {
  background-color: #aaf9ff;
  border: solid 1px #00ecff;
}

.severity-badge_midum {
  background-color: #f5e97f;
  border: solid 1px #ffe500;
}

.severity-badge_high {
  background-color: #ffdc9c;
  border: solid 1px #ffa500;
}

.severity-badge_informational {
  background-color: #DEEBF7;
  border: solid 1px #a7cbe3;
}

.severity-badge_notAvailable {
  background-color: #c5d6fd;
  border: 1px solid #95b0f0;
}

//flow metrics
.status-badge_NotWorked {
  background-color: #ff7575;
  border: solid 2px #f00;
}

.status-badge_InProgress {
  background-color: #f5e97f;
  border: solid 2px #ffe500;
}

.status-badge_Completed {
  background-color: #71FF86;
  border: solid 2px #27CA40;
}

///loder

.panel.panel-filled .panel-body {
  padding-top: 10px;
}

.ld-loading .panel-body {
  position: relative;
}

.ld-loading .panel-body>* {
  transition: opacity 0.3s;
  opacity: 0.2;
}

.ld-loading .panel-body:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1060;
}

.ld-loading .loader_table {
  display: block;
}

.loader_table {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100px;
  width: 100%;
  background-color: white;
  // top: 531px;
}

.loader-bar,
.loader-bar:before,
.loader-bar:after {
  background: var(--primary);
  -webkit-animation: load-bar-an 1s infinite ease-in-out;
  animation: load-bar-an 1s infinite ease-in-out;
  width: 0.66em;
  height: 0.66em;
}

.loader-bar {
  color: var(--primary);
  text-indent: -9999em;
  margin: auto auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  top: 50px;
}

.loader-bar:before,
.loader-bar:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader-bar:before {
  left: -1em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader-bar:after {
  left: 1em;
}

@-webkit-keyframes load-bar-an {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 2em;
  }

  40% {
    box-shadow: 0 -1em;
    height: 2.6em;
  }
}

@keyframes load-bar-an {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 2em;
  }

  40% {
    box-shadow: 0 -1em;
    height: 2.6em;
  }
}

.no-data {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #042350 !important;
  font-size: 13px !important;
  font-weight: bold;
}

.modelBodyResources {
  word-wrap: break-word;
}

a {
  color: #000000 !important;
}

.meanpop-subhead ul {
  display: flex;
  flex-wrap: wrap;
}

.meanpop-subhead li {
  flex-basis: 100%;
  display: flex;
}

.manageEnabled.btn-primary:hover {
  background-color: white !important;
  color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  font-size: 13px !important;
  padding: 8px !important;
}

.manageDisabled:disabled {
  background-color: var(--primary);
  border: none;
  font-size: 13px;
  padding: 8px;
}

.manageEnabled.btn-primary {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  font-size: 13px !important;
  padding: 8px !important;
}


/* width */
@-moz-document url-prefix() {

  .graphScroll,
  .custom_scroll,
  .flowDrill_scroll {
    overflow: auto;
    scrollbar-color: #adadad #f1f1f1;
    scrollbar-width: thin;
  }
}

.graphScroll::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
.graphScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.graphScroll::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 15px;
}

/* Handle on hover */
.graphScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Default styles for the X-axis and Y-axis labels */
.xAxisLabel,
.yAxisLabel {
  text-align: center;
  font-size: 12px;
  padding: 10px;
}

.yAxisLabel {
  writing-mode: vertical-lr;
  /* Use vertical-lr to position labels to the side */
  transform: rotate(180deg);
  /* Rotate labels 180 degrees for correct orientation */
  white-space: nowrap;
  /* Prevent label text from wrapping */
  // margin-left: -17px;
  margin-top: 28px;
  position: absolute;
}

#comlince_drill_details.meanpop-subhead ul {
  display: -webkit-box;
  margin-bottom: 5px;
}

#comlince_drill_details.meanpop-subhead li {
  flex-basis: auto;
}

// homepage
.info-box {
  padding: 9px 10px;
  border-bottom: 1px solid #E4E4E4;
  text-align: left;
  margin-bottom: 10px;
  cursor: pointer;
}

.info-box h4,
.info-box p {
  margin: 0 !important;
}

.info-box p {
  font-size: 13px;
  font-weight: normal;
}

.info-box h4 {
  color: var(--text) !important;
  text-transform: none !important;
  margin-bottom: 6px !important;
}

.defaultCursor {
  cursor: default;
}

.border-table table tr.thead {
  border-bottom: 3px solid var(--primary);
}

.border-table table tr {
  border-bottom: 1px solid var(--border);
}

.border-table table tr th {
  font-weight: var(--semibold);

  color: var(--text);
  padding: 14px 14px;
  white-space: nowrap;
  text-align: initial;
}

.border-table table tr td {
  font-size: var(--font13);
  font-weight: var(--regular);
  color: var(--text);
  padding: 14px 14px;
  text-align: initial;
  vertical-align: top;
}

.scroll-verticle {
  max-height: 300px;
}

.search_class {
  display: block;
  width: 100%;
  padding: 0.575rem 0.75rem;
  font-size: 13px;
  font-weight: 400;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0px 0px 0px -4px;
}

.webKitCenter {
  text-align: -webkit-center;
}

.text-align-start {
  text-align-last: start;
}

.table-search {
  margin-top: 20px;
}

.float-right {
  float: right;
}

th.noSort span {
  display: none;
}

.center-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs .tabsClass {
  display: flex;
  justify-content: flex-start;
}

.tabs button {
  padding: 10px;
  margin-right: 10px;
  border: none;
  background-color: transparent;
  color: #000;
  cursor: pointer;
}

.tabs button.active {
  font-weight: bold;
}

.ChatBot {
  margin-top: 1px;
}

.userCount {
  padding-left: 7px !important;
}

.optionBar {

  width: 200px !important;
  margin-top: 2px;
  margin-right: 5px;
  margin-bottom: 2px;
}

#Description {
  min-height: 6em;
  max-height: 22.5vw;
  width: 100%;
  background: #f2f4f2;
  resize: none !important;
  color: var(--gray3);
  font-size: var(--font13);
  padding: 16px;
  border: none;
  border-radius: 6px;
  min-width: 100px;
}

.vrs {
  min-height: 6em;
  max-height: 13vw;
  width: 100%;
  background: #f2f4f2;
  resize: none !important;
  border-radius: 6px;
  min-width: 100px;
}

.Appscroll {
  overflow-x: scroll;
}

.graphblock {
  text-align: left !important;
}

.card {
  box-shadow: 5px 5px 10px #b8b8b8, -5px -5px 10px #ffffff;
}

.chartHolder .projectcreation1 {
  height: 350px;
}

.graph-des-nav li span.light-green {
  background-color: #abff98;
}

.graph-des-nav li span.dark-green {
  background-color: #26a65b;
}

.graph-des-nav li span.dark-red {
  background-color: #fc120a;
}

.axis-Label {
  color: black;
  font-family: sans-serif;
  font-size: 14px;
}

.riskAssessment {
  width: 40vh;
  height: 0vh;
}

.remove-button {
  border: 0;
  background: none;
  padding: 0px 5px 0px 15px;
  font-size: 13px;
}

.nowrap {
  white-space: nowrap;
}

.legend-container {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: default;
}

.Vmlihead {
  min-width: 30px !important;
  text-transform: none;
  text-transform: capitalize !important;
}

.meanpop-subhead ul li span.Svalue_drill, .Svalue_drill {
  font-weight: normal !important;
}

.text-right {
  text-align: right;
}

@-webkit-keyframes load-chat-an {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 2em;
  }

  40% {
    box-shadow: 0 -1em;
    height: 2.6em;
  }
}

@keyframes load-chat-an {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 2em;
  }

  40% {
    box-shadow: 0 -1em;
    height: 2.6em;
  }
}

.shfChat {
  text-align-last: right;
  position: absolute;
  right: 15px;
}

.tableShfdiv {
  width: 90%;
  margin-left: -20px;
}

.flowpredi-des .vulnerablityButtoninvisible {
  display: none;
}

a.solid-btn.greenBtnStyle.alert_mail.disabled {
  cursor: default;
  background-color: #c1cca4;
}

.page-link:focus {
  z-index: 3;
  color: #ffffff;
  background-color: var(--primary);
}

.Plugin-Bold {
  font-size: 18px;
  font-weight: bold;
  color: var(--content-color);
  margin-right: 10px;
  white-space: nowrap;
  margin-bottom: 30px;
}

.display-content {
  text-transform: none !important;
  font-size: 16px;

  span {
    text-transform: capitalize !important;
    font-size: 16px;

  }
}

.pluginalign {
  text-align: left;
  vertical-align: top;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;

}

.info-box p {
  font-weight: normal;
}

.delete-h {
  font-size: 18px !important;
}

.bg-accordionGrey {
  background-color: rgb(128 128 128 / 13%) !important;
}

.userStatus-badge {
  display: inline-block;
  width: 100px;
  height: 18px;
  border-radius: 3px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  border: none !important;
}

td.tdico.sideBorder {
  border: 1px solid #d5d9dc !important;
  border-right: none !important;
  border-bottom: none !important;
  border-top: none !important;
}

.nonCompliant {
  background-color: #ff6060 !important;
  color: white !important;
  border: none !important;
}

.nonCompliant:hover,
.nonCompliant:disabled {
  background-color: #ff6060 !important;
  color: white !important;
  border: none !important;
  opacity: 0.8 !important;
}

.navigateStyle {
  cursor: pointer;
}

.faInfoCircleStyle {
  color: var(--primary)
}

.custom_scroll::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #c1c1c1 0%, #c1c1c1 100%);
}

.custom_scroll::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #c1c1c1 0%, #c1c1c1 100%);

}

.pagination {
  --bs-pagination-color: #102250 !important;
  --bs-pagination-font-size: 0.8rem;
}

.currentPage nav_1 {
  font-size: 13px;
}

.custom-select div.value-container {
  padding: 5px 10px 5px 10px !important;
}

.grid-table .solid-btn {
  padding: 8px 20px;
}

.homeSelectedApp {
  background-color: var(--primary-active) !important;
  color: #FFFFFF;
}

//common button styles
.btnStyle.btn-primary {
  min-width: 90px;
  font-size: var(--button-size) !important;
  background-color: var(--button-bg-color) !important;
  color: var(--button-text-color);
  margin-left: 1px !important;
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid var(--button-bg-color);
}
.btn-success{
  margin-left: 1px;
}
.btnStyle:hover {
  background-color: var(--button-hover-bg-color) !important;
  color: var(--button-hover-text-color) !important;
  border: 1px solid bar(--button-bg-color) !important;
}

.btnStyle:disabled {
  cursor: not-allowed !important;
}


.cancelBtnStyle.btn-primary  {
  min-width: 90px;
  font-size: var(--button-size);
  background-color: #606060 !important;
  color: #FFFFFF !important;
  border: 1px solid #606060 !important;
  margin-left: 10px;
  height: 40px;
  margin-bottom: 20px;
}

.cancelBtnStyle:hover {
  background-color: #FFFFFF !important;
  color: #606060 !important;
  border: 1px solid #606060;
}

.greenBtnStyle.btn-primary {
  min-width: 90px;
  font-size: var(--button-size);
  background-color: var(--secondary) !important;
  color: #42560a !important;
  border: 1px solid var(--secondary) !important;
  margin-left: 10px !important;
  height: 40px;
  margin-bottom: 20px !important;
}

.greenBtnStyle:hover {
  background-color: #FFFFFF !important;
  color: #42560a !important;
  border: 1px solid #42560a;
}

.serverInput input.form-control {
  border: 1px solid var(--secondary-light)
}

.form-control {
  font-size: 13px !important;
  border: 1px solid var(--primary-active) !important;
  height: 39px;
}

.mini-model-panal {
  background-color: #ededed;
  padding: 15px 0px 5px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}

.mini-model-table {
  width: 100%;
}

.custom-off-canvas .table-responsive.custom_scroll {
  max-height: 30vw;
  overflow-y: auto;
}

.table-responsive.custom_scroll {
  max-height: 580px;
  overflow-y: auto;
  //height: 189px;
}
@media (min-height: 798px) and (max-height: 958px) {
  .table-responsive.custom_scroll {
    max-height: 600px;
  }
}
@media (min-height: 958px) and (max-height: 1278px) {
  .table-responsive.custom_scroll {
    max-height: 700px;
  }
}
@media (min-height: 1278px) and (max-height: 2558px) {
  .table-responsive.custom_scroll {
    max-height: 1000px;
  }
}

.noscroll {
  max-height: 500px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.custom-off-canvas .custom_scroll {
  overflow-y: auto;
  max-height: 60vw;
}

.mini-model-table tbody tr {
  border: solid 5px #ededed;
}

.mini-model-table td {
  padding: 10px;
  background-color: #ffffff;
  word-break: break-word;
  border: 5px solid #ededed;
}

.mini-model-table th {
  padding: 5px 10px !important;
  border: 5px solid #ededed;
  background: white;
}

.mini-model-table td:last-child {
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
}

.mini-model-table td:first-child {
  border-bottom-left-radius: 13px;
  border-top-left-radius: 13px;
}

.circular09Table .custom_scroll {
  max-height: 60vh !important;
}

.serverInput button.btn {
  border: 1px solid var(--secondary-light)
}

.count_wid {
  width: 72px;
}

.sl_no_wid {
  width: 22px;
}

.textAreaTextManage {
  border: 1px solid;
  padding: 5px;
  border-radius: 0px;
  margin: -5px 0px 0px 0px;
  background-color: #f2f4f2;
  position: relative;
  z-index: 1;
}

.search {
  margin-bottom: 16px;

  .rowsPerPage {
    float: left;
    width: 50%;
  }

  .search {
    float: right;
    margin-right: 5px;
    width: 20%;

    .searchIconStyle {
      border: 1px solid #000;
    }
  }
}

.rowsPerPage {
  float: left;
}

.manageVulStyle {
  float: right;
  margin-right: 4px;
}

.searchStyle {
  width: 190px !important;
  margin-right: 4px;
  margin-left: 1px;
  float: right;
  font-size: var(--font13);
}

.userCRF {
  font-size: 14px !important;
}

.userCRPF {
  font-size: 16px !important;
}

.customTab {
  padding: 0 5px !important;
}

.customTab .nav-item:last-child {
  margin: 0 !important;
}

#Description.Mail_message {
  max-height: 16.5vw;
}

.config_cover {
  padding: 0 5px;
}

.graphScrollTreeMap {
  height: 24vw;
  overflow-y: auto;
  overflow-x: hidden;
}
.treeChartAlignment{
  margin: 0px 0px 0px 80px;
}

.errorAlighn {
  white-space: nowrap;
}

.checkBox {
  margin: 64px 0px 0px 5px !important;
}

div.labelHidden label {
  visibility: hidden;
  height: 20px;
}

.filepath {
  font-size: 12px;
}

.btn-Height {
  min-height: 38px;
}

.srvrTableFilters {
  width: 200px;
  margin-right: 5px;
}

.DaysColour {
  border: 1px solid var(--primary-active);
  background-color: #e9ecef !important;
  border-radius: 0px 10px 10px 0px;
  padding: 5px;
}

.max-h20vw {
  max-height: 20vw !important;
}

.max-h10vw {
  max-height: 10vw !important;
}

.echarts-for-react {
  height: -webkit-fill-available !important;
}

.vulBoxes {
  margin: 2px;
  width: 100%;
  color: #000;
  padding: 5px 0px;

  h4 {
    font-size: medium !important;
    margin: 3px !important;
    border-bottom: 1px solid #646464;
    padding-bottom: 5px !important;
  }

  h5 {
    font-weight: 500;
    padding-bottom: 2px;
    margin: 0 !important;
  }

  p {
    font-size: 10px;
    margin-bottom: 5px;
    margin-top: 8px;
  }
}

#UserData {
  width: 100%;
  border-collapse: collapse;
  border-color: inherit;
}

#UserData td,
#UserData th {
  // text-align: center;
  border: 1px solid #ddd;
  padding: 8px;
}

#userDrillClick {
  cursor: pointer;
  color: #0d6efd;
  text-decoration: underline;
}

.TypeHeadBorder .css-qyzydv-control {
  border: 1px solid #d70000;
}

.TypeHeadBorder .css-13cymwt-control {
  border: 1px solid #d70000 !important;
}

.tooltip-tHead .tooltip-inner {
  width: auto;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.detailsCursorTooltip {
  text-align: center;
  cursor: pointer;
}

.templateCursor {
  text-align: center;
}

.tooltip-severityBox>.tooltip-inner {
  color: var(--white);
  width: 100px;
  font-size: 0.70em;
  font-weight: 700;
  height: auto;
  border-radius: 3px;
  padding: 2px;
  margin: 2px;
}

.tooltip-severityBox>.tooltip-arrow::before {
  margin-left: -5px !important;
}

.tooltipHeader {
  opacity: 0;
  /* Start with opacity set to 0 */
  transition: opacity 0.3s ease;
  background-color: #ededed;
  color: var(--primary);
  margin: 0px 0px 8px 0px;
  border-radius: 5px !important;
  border: 1px var(--primary);

  .tooltip-inner {
    border: 1px solid black;
    /* Specify the border style */
    border-radius: 5px !important;
    background-color: #ededed;
    color: var(--primary);
  }

}

.tooltipHeader.show {
  opacity: 1;
}

.tooltipHeader>.tooltip-arrow::before {
  //border: black transparent transparent transparent !important;
  margin-left: -5px !important;
  top: 98%;
}

.NodataAvailable {
  background: white !important;
  color: var(--primary) !important;
  text-align: left !important;
  position: relative !important;
  text-align: center !important;
  padding: 131px !important;
  height: 50px !important;
  top: 0px !important;
  bottom: 10px !important;
  font-size: 13px !important;
  font-weight: bold !important;
}

.NodataAvailableVMTDD {
  padding: 122px 0px 132px 0px !important
}

.tooltipHeader .tooltip-inner {
  background-color: #ededed;
  color: var(--primary);
  border: black;
  opacity: 1;
}

.accessDisabled:disabled {
  background-color: var(--primary) !important;
  border: none;
  pointer-events: none;
}

.cancelbtn,.cancelbtn.btn-primary {
  background-color: #606060 !important;
  border: 1px solid #606060 !important;
}

.cancelbtn:hover {
  background-color: #f5f7f5 !important;
  color: #42560a !important;
  border: 1px solid #42560a !important;
}

.modlControlId p {
  font-size: 14px !important;
}

.modlControlIdHead th {
  font-size: 16px !important;
}

.modlControlIdHead td {
  font-size: 16px !important;
  font-weight: 500;
}

.vmPopDateInput {
  padding: 0.55rem 0.75rem !important;
  border: 1px solid var(--primary-active);
}

.stories-list {
  max-height: unset;
  overflow-y: unset;
}

label.form-label {
  margin: 0;
}

.gridtitleflowMetrics {
  padding: 2px 0px 10px 0px;
  color: #102250;
}

.not-allowed {
  cursor: not-allowed;
}

.pointer-events-none {
  pointer-events: none !important;
}

.cisPlugun-scroll .custom_scroll {
  max-height: 55vh !important;
}

svg.big_up_arrow {
  font-size: 14px;
  position: relative;
  bottom: 2px;
  color: #2d2b3e;
  cursor: default;
}

svg.small_up_arrow {
  font-size: 9px;
  position: relative;
  bottom: 2px;
  color: #2d2b3e;
  cursor: default;
}

.head_rigt_text {
  font-size: 13px;
  font-weight: var(--regular);
  text-align: right;
  margin: 0 0 0 auto;
  cursor: default;
}

.actionPaddingVM {
  padding: 10px 0px 0px 0px;
}

.cancelPaddingVm {
  margin: 9px 0px 0px 0px;
}

.actionDescError {
  display: table;
}

.serverTdSl {
  width: 0px;
}

.schedule-tool {
  font-size: 18px !important;
  border-bottom: 0px !important;
}

.exportbtn {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary);
  font-size: 13px;
  padding: 8px;
}

.exportbtn:hover {
  background-color: var(--primary-light) !important;
  color: var(--primary);
}

#legend-container {
  background-color: #fff;
  /* left: 71px; */
  padding: 15px 0px 41px;
  position: fixed;
  /* top: 41px; */
  z-index: 1000;
}

.vultreewidth {
  width: 100%;
}

.tablecontrolid2 {
  margin-left: -1%;
  text-wrap: nowrap;
}

.displayhead {
  display: flex;
}

.srsSeverity {
  // background: #ffffff;
  // z-index: 998;
  // overflow: hidden;
  // height: 82%;
  // padding: 105px 20px;
  // position: relative;
  // display: grid;
  // justify-items: center;
  height: 16vw;
}

.TRMGmanageFontSize {
  font-size: 15px !important;
}

.custom-select div.option.focused {
  background-color: rgba(177, 188, 147, 0.56);
}

input:focus,
textarea:focus {
  outline: 0;
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none !important;
}

.btn-close:focus {
  box-shadow: none !important;
}

.menu-container input:focus,
.menu-container button:focus {
  box-shadow: none !important;
}

.usertags {
  border: 1px solid #18347c;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
}

.yAxispositioningLinechart {
  rotate: 270deg;
  position: absolute;
  margin: 143px 0px 0px -40px;
}

.customYAxispositioning {
  margin: 143px 0px 0px -20px !important;
}

.yAxispositioningLinechartMultiple {
  rotate: 270deg;
  position: absolute;
  margin: 141px 0px 0px -28px;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
}

.react-pdf__message--error,
.react-pdf__message {
  text-align: center;
  margin-bottom: 50px;
}

.react-pdf__message--error,
.react-pdf__message {
  text-align: center;
  margin-bottom: 50px;
}

.input-number-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.page {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 11.1727px;
  color: #595959;
  padding-right: 8px;
  padding-top: 3px;
}

.tooltip-network {
  position: absolute;
  bottom: 40px;
  /* Adjust this value based on your icon's position */
  right: 0;
  background-color: #edf2ef;
  /* Tooltip background color */
  color: black;
  /* Tooltip text color */
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  /* Adjust font size */
  z-index: 1000;
  /* Ensure tooltip is above other elements */
  white-space: nowrap;
  /* Prevent text wrapping */
}

.tab-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tab {
  padding: 8px 16px;
  border-radius: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  color: #333;
  font-size: 14px;
  font-weight: 500;
}

.tab:hover {
  border-color: #999;
}

.tab.selected {
  background-color: #1A2353;
  border-color: #1A2353;
  color: white;
}

.linebreak {
  white-space: normal;
  word-wrap: break-word;
  max-width: 200px;
}

.popup-content{
  user-select: none;
}

//checkbox
.checkbox-group {
  display: flex;
  gap: 16px;
  margin-top: 8px;
  margin-left: 22px;
}

.checkbox-item {
  display: flex;
  align-items: center;
}

.checkbox-name {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: normal !important;
  margin-left: 4px;
}

.breadcrumb-container {
  flex: 0 1 auto;
}

.UARSearch {
  margin-top: -20px;
}

.critical-count {
  margin-top: -1px;
  margin-bottom: 0px;
  margin-left: 2px;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  white-space: nowrap;
}

.string-result {
  margin-left: 2px;
  font-size: 10px;
  white-space: nowrap;
}

/* Responsive styles for smaller screens */
@media (max-width: 600px) {

  .xAxisLabel,
  .yAxisLabel {
    font-size: 12px;
    padding: 0px;
  }
}

/* Media query for screens up to 991px */
@media (max-width: 991px) {
  .actright {
    flex-wrap: wrap;
  }

  .actright .filterSelect {
    margin: 4px 0px 0px 0px;
  }

  .actright .ComplianceFilterStyle {
    margin: 5px 0px 0px 0px;
  }
}


/* Media query for screens up to 1242px */
@media (max-width: 1242px) {
  .critical-count {
    margin-top: -1px;
    margin-bottom: 0px;
    margin-left: 2px;
    display: flex;
    flex-direction: column;
    font-size: 7px;
    white-space: nowrap;
  }

  .string-result {
    margin-left: 2px;
    font-size: 7px;
    white-space: nowrap;
  }
}
.borderHideRowSpan {
  border: 1px solid #dbdfdd;
}
.Custom_tooltip {
  position: relative;
  display: inline-block;
}

.Custom_tooltipText {
  visibility: hidden;
  width: 120px;
  background-color: var(--bs-emphasis-color); /* Use Bootstrap variable */
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px !important;
}

.Custom_tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.Custom_tooltip:hover .Custom_tooltipText {
  visibility: visible;
  opacity: 1;
  // font-size:12px;
}
.tooltip-topGreen {
  background-color: #27ca40; /* Background for green tooltip */
}

.tooltip-topGreen::after {
  border-color: green transparent transparent transparent; /* Arrow color */
}

/* Yellow Tooltip */
.tooltip-topYellow {
  background-color: yellow; /* Background for yellow tooltip */
  color: black; /* Text color for yellow tooltip */
}

.tooltip-topYellow::after {
  border-color: yellow transparent transparent transparent; /* Arrow color */
}

/* Red Tooltip */
.tooltip-topRed {
  background-color: red; /* Background for red tooltip */
}

.tooltip-topRed::after {
  border-color: red transparent transparent transparent; /* Arrow color */
}
.headerLift{
  margin-top: -28px;
  padding: 0px 12px;
  background-color: #dcdfdd;
}
.vulColumnTextStyle{
  margin: -8px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.filterResetBtnStyle:hover{
    cursor: pointer !important;
}
.vulColumnTextStyleDD{
font-size: 9px;
text-align: left;
margin: -8px 0px;
display: flex;
flex-direction: column;
justify-content: center;
height: 100%;
}
.heightDrillDownAsset{
  height: 300px;
}
.tooltip1 {
  // position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip1::after {
  content: attr(data-tooltip);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px 8px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1;
}

.tooltip1:hover::after {
  opacity: 1;
  visibility: visible;
  transform: translateY(-100%);
  // left: 25%;
  transform: translateX(-50%) translateY(-120%);
}
// .tooltip-placement-top {
//   transform: translate(-50%, -100%);
//   top: -8px;
//   left: 50%;
// }

.tooltip-placement-bottom {
  transform: translate(-50%, 100%);
  bottom: -8px;
  left: 50%;
}

.tooltip-placement-left {
  transform: translate(-100%, -50%);
  left: -8px;
  top: 50%;
}

.tooltip-placement-right {
  transform: translate(100%, -50%);
  right: -8px;
  top: 50%;
}

.Custom_tooltipText::after {
  content: "";
  position: absolute;
  border-style: solid;
}

/* Arrow for Top Placement */
.tooltip-placement-top::after {
  top: 100%; /* Below the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-color: #333 transparent transparent transparent;
}

/* Arrow for Bottom Placement */
.tooltip-placement-bottom::after {
  content: "";
  position: absolute;
  top: -8px; /* Move the arrow above the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}

/* Arrow for Left Placement */
.tooltip-placement-left::after {
  left: 100%; /* To the right of the tooltip */
  top: 50%;
  transform: translateY(-50%);
  border-width: 8px;
  border-color: transparent transparent transparent #333;
}

/* Arrow for Right Placement */
.tooltip-placement-right::after {
  right: 100%; /* To the left of the tooltip */
  top: 50%;
  transform: translateY(-50%);
  border-width: 8px;
  border-color: transparent #333 transparent transparent;
}
.first-col{
  margin-top: 20px;
}
.second-col2 {
  position: relative;
  bottom: 20px;
}

.second-col3 {
  position: relative;
  bottom: 40px;
}
.assessmentcontent{
  margin-left:100px;
}
.expanded-toggle-bg {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
}
.InvenReport {
  font-size: 24px;
  font-weight: 100;
}
.InvenReportStatus {
  color: green;
  font-weight: 700;
}

.InvServerTable {
  margin: 5px !important;
}
.InvServerTable table tr td {
  padding: 5px 20px !important;
}
.InvServerTable table tr td {
  padding: 5px 20px !important;
}
.InvServerTable .Table-Header {
  margin-bottom: 10px !important;
}
.infoserverTable th {
  background-color: rgb(220, 223, 221) !important;
}
.infoserverTable thead{
  border-radius: 2px !important;
}
.InvServerModal .btn-close {
  margin-top: 9px !important;
}
.xs div{
  width: 414px !important;
}

.InvServerTable-Info {
  margin: 5px !important;
}
.InvServerTable-Info table tr td {
  padding: 5px 20px !important;
}

.InvServerTable-Info .Table-Header {
  margin-bottom: 10px !important;
}
.InvServerTable-Info .custom_scroll {
  max-height: 260px !important;
  overflow-y: auto;
}
.InvServerTable-InfoNoloading .projectemty{
  padding: 10px 20px !important;
}
.InvServerTable-Infoloading .projectemty{
  padding: 0px !important;

}
.InvServerTable .table-responsive {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}