@import "./root.scss";

//nav bar

.firstChildNav-heading.noborder {
  border-bottom: none;
}

.firstChildNav-heading a.navIcon {
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
  border: none !important;
}

.firstChildNav-heading {
  font-weight: bold;
  text-align: left !important;
  flex-direction: row;
  display: flex;
  padding-left: 13px;
  border-bottom: 1px solid #18347c;
  padding-bottom: 11px;
  padding-top: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  position: relative;
  background: #fffffF;
}

.firstChildNav-heading-hr {
  margin: 0px 0px;
  border: none;
  border-bottom: 1px solid #515151;
  width: 100%;
}

#basic-navbar-nav {
  font-size: 15px;
}

#basic-nav-dropdown {
  white-space: nowrap;
  padding: 13px 15px;
  font-size: var(--font13);
  font-weight: var(--semibold);
  border: 0;
  border-radius: 6px px 0 0 6px;
  color: var(--black);
  width: 150px;
  text-align: left;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

#aadNav .dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 44%;
  display: none;
}

.homePageNave .m-nav {
  position: relative;
  width: inherit;
  margin: 3px 18px 0;
  padding: 3px;
}

.homePageNave .dashTopNav .nav_1 {
  padding: 8px 15px;
  background-color: #e8e8e8;
  white-space: nowrap;
  height: 38px;
  margin: 2px 0px;
  border-radius: 4px;
}

.custom_scrollNav {
  max-height: 180px;
  overflow-y: auto;
}

.custom_scrollNav::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

.custom_scrollNav::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom_scrollNav::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 10px;
}

.custom_scrollNav::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.nav.small a[tabIndex="0"]:focus {
  border-left: none;
  border-right: none;
}

.graph-des-nav li span.planned {
  background-color: #228b22;
}

.graph-des-nav li span.unplanned {
  background-color: #ff0404;
}

.graph-des-nav li span.exception {
  background-color: #fda26b;
}

.graph-des-nav li span.whitelisted {
  background-color: #79D2DE;
}

.graph-des-nav li span.approval_pending {
  background-color: #a9a9a9;
}


.m-nav ul li a:before {
  position: absolute;
  content: "";
  left: 0;
  width: 6px;
  height: 6px;
  background-color: #505050c4;
  border-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
}


#basic-nav-dropdown #nav-dropdown {
  padding: 0;
}

#aadNav ul li a.selected {
  display: none;
}

.naveLinkLi .nav-list {
  list-style: none;
  padding: 0;
}

.naveLinkLi .nav-list li {
  margin-bottom: 10px;
}

.naveLinkLi .nav-list li .bullet {
  margin-right: 5px;
  color: var(--primary);
}

.graphblock .navli {
  padding: 10px 0px !important;
}

.nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: var(--primary);
  // background-color: #10225014;
}

.nav-tabs .nav-link {
  color: #121212;
}

.m-nav li {
  display: inline-block;
  list-style-type: circle;
}

.m-nav a {
  font-size: var(--font13) !important;;
  font-weight: var(--regular) !important;;
  color: var(--gray) !important;
  padding: 10px 18px !important;;
  -moz-transition: all 0.2s ease-in !important;
  -o-transition: all 0.2s ease-in !important;;
  -webkit-transition: all 0.2s ease-in !important;;
  transition: all 0.2s ease-in !important;;
  position: relative !important;;
}

.directionnav {
  display: flex;
  margin: 0 18px 0 auto;
  align-items: center;
}

.directionnav #button-left {
  margin-right: 15px;
}

.directionnav svg {
  width: 10px;
}


#aadNav {
  display: flex;
  width: 100%;
  background: #ffff;
  border-radius: 10px;
}

.dashTopNav {
  max-width: 100% !important;
}

.dashTopNav .nav_1 {
  padding: 13px 15px;
  background-color: #e8e8e8;
  white-space: nowrap;
  font-size: var(--font13);
}

.dashTopNav .nav_1_img {
  padding: 13px 30px 13px 15px;
  background-color: #e8e8e8;
  white-space: nowrap;
  font-size: var(--font13);
}

.m-nav {
  position: relative;
  width: inherit;
  margin: 8px 18px 0;
  padding: 3px;
}

.bg-for-navBar {
  background-color: #D9D9D9;
}

.nav ul {
  text-align: center !important;
  margin: 25px auto 0 !important;
  padding: 0 !important;
}

.nav ul li {
  display: block;
  position: relative;
}

.nav ul li a {
  display: block;
  padding: 14px 18px;
  position: relative;
  margin: 5px auto;
}

.nav ul .dropdown-btn.active .navIcon {
  background-color: #354671;
  border-radius: 14px;
  width: 80%;
  padding: 10px;
}

.nav ul li a img {
  width: 25px;
  margin: 0 auto;
}

.nav ul li a svg {
  width: 25px;
}

.nav ul li a .svgfill {
  fill: #fff;
}

.nav ul li a:hover {
  background-color: var(--primary);
}

.nav ul li a:hover .svgfill {
  fill: var(--secondary);
}

.nav ul li.active a:after,
.nav ul li a:hover:after {
  height: 100%;
}

.nav ul li.active a {
  background-color: var(--primary);
}

.nav ul li.dropdown-btn:hover a:after {
  height: 100%;
}

.nav ul li.dropdown-btn:hover .dropdown-container {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.nav ul li.active .dropdown-container a {
  background-color:transparent !important;
}

.nav ul li a.active:after {
  height: 100%;
}

.navbar{
  padding-left: 5px !important;
  padding-right: 5px !important;
}