.custom-select {
  width: 100%;
  box-sizing: border-box;
}

.custom-select .control {
  border: 1px solid var(--primary-active);
}

.custom-select .control:hover {
  border: 1px solid var(--primary-active);
  cursor: pointer;
}

.custom-select .value-container {
  position: relative;
  width: 80%;
  text-align: left;
  font-size: var(--font13);
  font-weight: var(--regular);
  font-style: normal;
  color: var(--text);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: var(--white);
  padding: 10px 22px 10px 10px;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-select .options {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  width: 100%;
  padding: 10px 10px !important;
  margin: 1px 0;
  color: #000;
  cursor: pointer;
  font-size: 13px;
}

.custom-select .options:hover {
  background-color: rgb(177 188 147 / 56%);
}

.custom-select .options.selected {
  background-color: rgb(177 188 147 / 56%);
}

.custom-select .menuContainer {
  min-height: auto !important;
  max-height: fit-content !important;
  box-shadow: 0 3px 2px rgb(0 0 0 / 16%);
  border: 1px solid rgb(177 188 147 / 70%);
  margin-top: 1px;
  width: 100%;
  margin-bottom: 20px;
  background: white;
  position: absolute;
  z-index: 2000;
  text-align: left;
}

.custom-select .menuContainer .menu {
  position: initial;
  box-shadow: none;
  margin: 0;
}

.custom-select .menuContainer .menu .menu-list {
  padding: 0;
  z-index: 2000;
}

.custom-select .menuContainer .bs-searchbox input {
  font-size: var(--font14);
  font-weight: var(--regular);
  color: var(--gray2);
  background-color: var(--lightbg);
  width: 100%;
  padding: 18px 15px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid var(--secondary-light);
}

.custom-select .menuContainer input {
  width: 100%;
  padding: 4px 8px 5px 18px;
  border: none;
  border: 1px solid var(--primary);
  outline: none;
  background-color: #ffffff;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  height: 26px;
  color: #000;
}

.custom-select .menuContainer button {
  display: block;
  font-size: 0.8em;
  text-decoration: none;
  color: var(--primary);
  font-weight: 500;
  font-style: normal;
  text-align: left;
  margin: 4px 5px;
  border: none;
  background: none;
}

.custom-select .myRequest {
  width: 280px;
}
