.custom-select {
  width: 100%;
  box-sizing: border-box;
  .control {
    border: 1px solid var(--primary-active);
    &:hover {
      border: 1px solid var(--primary-active);
      cursor: pointer;
    }
  }
  div.value-container {
    position: relative;
    width: 80%;
    text-align: left;
    font-size: var(--font13);
    //  
    font-weight: var(--regular);
    font-style: normal;
    color: var(--text);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    background-color: var(--white);
    padding: 10px 22px 10px 10px;
    outline: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  div.option {
    &:hover {
      background-color: rgb(177 188 147 / 56%);
    }
  }
  div.option.selected {
    background-color: rgb(177 188 147 / 56%);
  }
  div.option label {
    position: relative;
    display: grid;
    grid-template-columns: 22px auto;
    align-items: center;
    width: 100%;
    padding: 5px 8px !important;
    margin: 1px 0;
    color: #000;
    font-size: 13px;
    font-weight: 400 !important;
    //  
    cursor: pointer;
    input {
      margin-right: 14px;
      /* position: absolute; */
      /* left: 10px; */
      /* top: 7px; */
      width: 14px;
      height: 14px;
    }
  }
  //Menu
  .menu-container {
    min-height: auto !important;
    max-height: fit-content !important;
    background: #fff;
    box-shadow: 0 3px 2px rgb(0 0 0 / 16%);
    padding: 10px;
    border-radius: 4px;
    position: absolute;
    // width: max-content;
    // max-width: 250px;
    // max-width: 250px;
    margin-top: 1px;
    margin-bottom: 20px;
    background: white;
    z-index: 2000;
    text-align: left;
    &::before {
      position: absolute;
      content: "";
      left: 15px;
      top: 20px;
      background-image: url(../../../assets/images/search.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 8px;
      height: 8px;
    }
    .menu {
      position: initial;
      box-shadow: none;
      margin: 0;
      .menu-list {
        padding: 0;
      }
    }
    input {
      width: 100%;
      padding: 4px 8px 5px 18px;
      border: none;
      border: 1px solid var(--primary);
      outline: none;
      background-color: #ffffff;
      font-style: normal;
      font-size: 12px;
      //  
      font-weight: 400;
      height: 26px;
      color: #000;
    }
    button {
      display: block;
      font-size: 0.8em;
      text-decoration: none;
      color: var(--primary);
      font-weight: 500;
      font-style: normal;
      text-align: left;
      margin: 4px 5px;
      border: none;
      background: none;
    }
  }
}
.fltr-dropdown-size .menu-container{
  width:unset !important;
  max-width: unset !important;
}
