.custom-select {
    position: relative;
    width: 200px;
    font-family: Montserrat, sans-serif;
    background-color: transparent;
    user-select: none;
    .select-input {
      // padding: 9px;
      // border: 1px solid var(--primary-active);
      // border-radius: 4px;
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      // cursor: pointer;
      -webkit-box-align: center;
      align-items: center;
      // min-width:205px;
      cursor: pointer;
      display: flex;
      // flex-wrap: wrap;
      -webkit-box-pack: justify;
      justify-content: space-between;
      min-height: 38px;
      position: relative;
      transition: 100ms;
      background-color: rgb(255, 255, 255);
      border-color: var(--primary-active);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      box-sizing: border-box;
      outline: 0px !important;
      .select-value{
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // font-size: 13px;
        // font-size:13px;
        background-color: var(--white);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    color: var(--text);
    font-size: var(--font13);
    font-style: normal;
    font-weight: var(--regular);
    outline: none;
    overflow: hidden;
    padding: 7px 22px 7px 10px;
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
      }
      .arrow {
        // margin-left: 8px;
        // width: 0;
        // height: 0;
        // border-left: 5px solid transparent;
        // border-right: 5px solid transparent;
        // border-top: 5px solid var(--primary-active);
        // transition: transform 0.3s ease;
        background-image: url(../../../assets/images/down-caret.svg);
        background-position: center;
        background-repeat: no-repeat;
    transition: color 150ms;
    color: rgb(204, 204, 204);
    padding: 8px;
    box-sizing: border-box;
  
        // &.open {
        //   transform: rotate(180deg); // Rotates arrow upwards
        // }
      }
      .alternatearrow{
        background-image: url(../../../assets/images/dropdownicon.svg);
        background-position: center;
        background-repeat: no-repeat;
    transition: color 150ms;
    color: rgb(204, 204, 204);
    padding: 8px;
    box-sizing: border-box;
      }
    }
    // .openDrop {
    //   box-shadow: 0 0 0 0.17rem rgba(49, 132, 253, 0.28) !important;
    // }

    .dropdown {
      position: absolute;
      width: 100%;
      // max-height: 200px;
      // overflow-y: auto;
      background: #fff;
      border: 1px solid #ddd;
      z-index: 999;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.16);
      scrollbar-color: #adadad #f1f1f1;
      scrollbar-width: thin;
      word-break: break-word !important;
      .dropdown-controls {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
  
        .clear-selection,
        .select-all {
          color: var(--primary);
          cursor: pointer;
          font-size: 0.8em;
        }
      }
  
    //   .dropdownSearch-input {

    // }
  
      .options-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        // padding-top: 8px;
        padding-top: 0px;
        max-height: 200px;
        overflow-x: auto;
  
        .option {
          padding: 6px;
          display: flex;
          align-items: center;
          cursor: pointer;
          /* white-space: nowrap; */
          margin: 1px;
          input{
            width: min-content;
          }
          &.disabled {
            color: #ccc;
            cursor: not-allowed;
          }
          &:hover {
            background-color: rgba(177, 188, 147, 0.56);
          }
          &.selected {
            background-color: rgba(177, 188, 147, 0.56);
            color: #000;
          }
          &.option.disabled {
            opacity: 0.5;
            pointer-events: none; 
            background-color: #b1bc938f;
          }
          .option-text {
            margin-left: 8px;
            margin-bottom: 6px;
            // font-size: 12px;
          }
          .select-all-text{
            margin-left: 8px;
            font-size: 13px;
          }
        }
      }
    }
  
    .error-message {
      color: red;
      font-size: 12px;
      padding: 4px 8px;
    }
  
    &.dark {
      color: #fff;
      background: #333;
  
      .select-input,
      .dropdown {
        background: #444;
        color: #fff;
      }
    }
  }

  .custom-select.disabled, .custom-select .disabled-input {
    background-color: rgb(242, 242, 242);
    cursor: not-allowed;
    pointer-events: none;
}
.selectAllOptionClass {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
  padding: 10px;
}
.selectAllOptionClass span.option-text {
  margin-left: 0px !important;
  margin-bottom: 0px !important;
  display: block;
  font-size: 0.8em;
  text-decoration: none;
  color: var(--primary);
  font-weight: 500;
  font-style: normal;
  text-align: left;
  border: none;
  background: none;
}
.selectAllOptionClass:hover{
  background-color:white!important;
}

.custom-select .dropdownSearch-input::before {
  content: "";
  background-image: url(../../../assets/images/search.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 10px;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
  pointer-events: none; 
}
.dropdown-border {
-webkit-box-align: center;
align-items: center;
align-self: stretch;
display: flex;
flex-shrink: 0;
box-sizing: border-box;
padding: 5px;
}
.custom-select .select-input .select-value.disabled {
  background-color: rgb(242, 242, 242);
  cursor: not-allowed;
  pointer-events: none;
}
.custom-select {
  .dropdown .options-list .option {
    input[type="checkbox"]:disabled {
      background-color: #d3d3d3 !important;
      border: #a9a9a9 !important; 
    }
  }
}

.select-input.disabled-input {
  background-color: #f0f0f0;
  cursor: not-allowed;
}
.option.disabled {
  opacity: 0.5;
  pointer-events: none; 
  background-color: #f5f5f5; 
}

.select-input.disabled-input {
  background-color: #f0f0f0;
  cursor: not-allowed;
}
.custom-select li.option label input {
  height: 14px;
  margin-right: 14px;
  width: 14px;
}