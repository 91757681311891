@import "./root.scss";

.modal {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 1055 !important;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background: #000000a8 !important;

  .sectitle {
      font-size: 20px;

      color: var(--primary);
      font-weight: var(--bold);
      margin-bottom: 25px;
  }

  .modal-content {
      border-radius: 23px;
  }

  btn-close {
      box-sizing: content-box;
      padding: 0;
      color: var(--primary);
      background: none;
      border: 0;
      border-radius: 0;
      opacity: 1;
      font-size: 30px;
      font-weight: 300;
      position: absolute;
      right: 20px;
      top: -1px;
      z-index: 999;
      font-family: 'Barlow', sans-serif;
  }

  .btnwrap {
      border-top: 1px solid #d5d5d5;
      padding: 27px 0 0;
      margin: 30px 0 0;
  }
}

.modal-backdrop.show {
  opacity: 0 !important;
  z-index: 1029 !important;
}

.modal-body p {
  font-size: 18px;
  font-weight: 400;
  color: #030303;
  text-align: center;
}

.modal-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: -55px;
}

.modal-dialog.request_panal {
  max-width: 900px;
}


.modal .btn-close {
  box-sizing: content-box;
  padding: 0;
  color: var(--primary);
  background: none;
  border: 0;
  border-radius: 0;
  opacity: 1;
  font-size: 30px;
  font-weight: 300;
  position: absolute;
  right: 5px !important;
  top: -5px !important;
  z-index: 999;
  font-family: "Barlow", sans-serif;
}

.modal-body2 {
  font-size: 19px !important;
}

.modal-dialog-centered .modal-body2 {
  width: 95%;
}

.errorModal .modal-content {
  color: #d70000;
  border: 2px solid #d70000;
}

.modelButton {
  background-color: #c6c7c800 !important;
  border-color: #babbbc00 !important;
}

.modelButton:active {
  background-color: #c6c7c800 !important;
  border-color: #babbbc00 !important;
}

.modelButton:hover {
  background-color: #c6c7c800 !important;
  border-color: #babbbc00 !important;
}

.modelButton:active {
  background-color: #c6c7c800 !important;
  border-color: #babbbc00 !important;
}

/* Media query for screens up to 768px */
@media (max-width: 768px) {
  .modal-header-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
}

/* Media query for screens up to 1093px */
@media (max-width: 1093px) {
  .modal-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 0px;
  }
}