@import "./root.scss";

.chipset-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.chipset {
    display: flex;
    align-items: center;
    border-radius: 25px;
    padding: 6px 12px;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    position: relative;
    padding-left: 32px;
    /* Offset for circular input */
}

.chipset input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #f1f1f1;
    border: 2px solid #ccc;
    position: absolute;
    left: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* When checkbox is checked */
.chipset input:checked {
    background-color: #007bff;
    border-color: #007bff;
}

/* Adding the tick mark */
.chipset input:checked::before {
    content: "";
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(45deg);
}

/* Style for the text inside the chip */
.chip {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    transition: color 0.3s ease;
}

.chipset:hover {
    border-color: #999;
}

/* When the chipset is selected (input is checked) */
.chipset.selected {
    border-color: #007bff;
    color: #007bff;
}

/* Style the text when the checkbox is checked */
.chipset input:checked+.chip {
    color: #007bff;
    font-weight: 500;
}