@import "./root.scss";
/*-----------date picker----------------*/
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  margin: 139px 0px 0px 10px;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  font-size: 12px;
}

.react-calendar__month-view__days__day--weekend {
  color: #420000 !important;
}

.react-calendar__tile--now {
  background: #522e8e00 !important;
  color: #000000 !important;
  border-radius: 4 !important;
}

.react-calendar__tile--active {
  background: var(--primary) !important;
  color: white !important;
  border-radius: 4px;
}

button.react-calendar__navigation__label {
  font-size: 12px;
  font-weight: 700;
}

.react-calendar__month-view__weekdays {
  font-size: 11px !important;
  text-transform: capitalize !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-date-picker__calendar.react-date-picker__calendar--open {
  inset: auto 0px 0% auto !important;
}