@import "./root.scss";

.tooltip-topGreen>.tooltip-inner {
    background-color: #fff;
    color: var(--content-color);
    border: 3px solid #27CA40;
    background-color: #27CA40;
    font-size: var(--content-size);
}

.tooltip-topYellow>.tooltip-inner {
    background-color: #fff;
    color: var(--content-color);
    border: 3px solid #FFC130;
    background-color: #FFC130;
    font-size: var(--content-size);
}

.tooltip-topRed>.tooltip-inner {
    background-color: #fff;
    color: var(--content-color);
    border: 3px solid #E14942;
    background-color: #E14942;
    font-size: var(--content-size);
}

.tooltip-topGreenTable>.tooltip-inner {
    background-color: #fff;
    color: var(--content-color);
    border: 1px solid #27CA40;
    background-color: #27CA40;
    font-size: 0.70em;
    font-weight: 700;
    height: auto;
    padding: 0px 10px;
    width: auto;
    border-radius: 3px;
}

.tooltip-topYellowTable>.tooltip-inner {
    background-color: #fff;
    color: var(--content-color);
    border: 1px solid #FFC130;
    background-color: #FFC130;
    font-size: 0.70em;
    font-weight: 700;
    height: auto;
    padding: 0px 10px;
    width: auto;
    border-radius: 3px;
}

.tooltip-topRedTable>.tooltip-inner {
    background-color: #fff;
    color: var(--content-color);
    border: 1px solid #E14942;
    background-color: #E14942;
    font-size: 0.70em;
    font-weight: 700;
    height: auto;
    padding: 0px 10px;
    width: auto;
    border-radius: 3px;
}

.tooltip-topGreenTable>.tooltip-arrow::before {
    border-color: #27CA40 transparent transparent transparent !important;
    margin-left: -5px !important;
}

.tooltip-topYellowTable>.tooltip-arrow::before {
    border-color: #FFC130 transparent transparent transparent !important;
    margin-left: -5px !important;
}

.tooltip-topRedTable>.tooltip-arrow::before {
    border-color: #E14942 transparent transparent transparent !important;
    margin-left: -5px !important;
}

.tooltip-medium>.tooltip-inner {
    background-color: #f5e97f;
    color: var(--content-color);
    border: 1px solid #ffe500;
    width: auto;
    font-size: 0.70em;
    font-weight: 700;
    height: auto;
    padding: 0px 10px;
    border-radius: 3px;
}

.tooltip-Info>.tooltip-inner {
    background-color: #DEEBF7;
    color: var(--content-color);
    border: 1px solid #a7cbe3;
    width: auto;
    font-size: 0.70em;
    font-weight: 700;
    height: auto;
    padding: 0px 10px;
    border-radius: 3px;
}

.tooltip-High>.tooltip-inner {
    background-color: #ffdc9c;
    color: var(--content-color);
    border: 1px solid #ffa500;
    font-size: 0.70em;
    font-weight: 700;
    width: auto;
    height: auto;
    padding: 0px 10px;
    border-radius: 3px;
}

.tooltip-notAvailable>.tooltip-inner {
    background-color: #c5d6fd;
    color: var(--content-color);
    border: 1px solid #95b0f0;
    width: auto;
    font-size: 0.70em;
    font-weight: 700;
    height: auto;
    padding: 0px 10px;
    border-radius: 3px;
}

.tooltip-Critical>.tooltip-inner {
    background-color: #ff7575;
    color: var(--content-color);
    border: 1px solid #f00;
    width: auto;
    font-size: 0.70em;
    font-weight: 700;
    height: auto;
    padding: 0px 10px;
    border-radius: 3px;
}

.tooltip-Low>.tooltip-inner {
    background-color: #aaf9ff;
    color: var(--content-color);
    border: 1px solid #00ecff;
    width: auto;
    font-size: 0.70em;
    font-weight: 700;
    height: auto;
    padding: 0px 10px;
    border-radius: 3px;
}

.tooltip-medium>.tooltip-arrow::before {
    border-color: #d4ba00 transparent transparent transparent !important;
    margin-left: -5px !important;
}

.tooltip-Info>.tooltip-arrow::before {
    border-color: #DEEBF7 transparent transparent transparent !important;
    margin-left: -5px !important;
}

.tooltip-High>.tooltip-arrow::before {
    border-color: #e58500 transparent transparent transparent !important;
    margin-left: -5px !important;
}

.tooltip-Critical>.tooltip-arrow::before {
    border-color: #cc0000 transparent transparent transparent !important;
    margin-left: -5px !important;
}

.tooltip-Low>.tooltip-arrow::before {
    border-color: #00b8cc transparent transparent transparent !important;
    margin-left: -5px !important;
}


.tooltip_progress {
    position: absolute;
    top: -39px;
    //right: -23px;
    // color: white;
    visibility: visible;
    width: 55px;
    padding: 5px;
    box-shadow: 6px 6px 10px #b8b8b8, -5px -5px 10px #ffffff;
    border-radius: 5px;
}

.tooltip_progress.color_1 {
    border: 1px solid #fc2009;
}

.tooltip_progress.color_2 {
    border: 1px solid #f8a503;
}

.tooltip_progress.color_3 {
    border: 1px solid #f6f000;
}

.tooltip_progress.color_4 {
    border: 1px solid #2ca958;
}

.tooltip_progress.color_1::after {
    border-color: #fc2009 transparent transparent transparent;
}

.tooltip_progress.color_2::after {
    border-color: #f8a503 transparent transparent transparent;
}

.tooltip_progress.color_3::after {
    border-color: #f6f000 transparent transparent transparent;
}

.tooltip_progress.color_4::after {
    border-color: #2ca958 transparent transparent transparent;
}

.tooltip_progress::after {
    content: '';
    position: absolute;
    top: 100%;
    left: -1%;
    transform: translateX(50%);
    border-width: 5px;
    border-style: solid;
}

.tooltip {
    display: inline;
    position: relative;
    opacity: inherit;
    text-align: center;
}

.tooltip_on {
    position: relative;
    display: inline-block;
}

.tooltip_on .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    font-size: 12px;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-inner {
    width: 120px;
    background-color: #000000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-size: 12px;
    z-index: 1;
    bottom: 125%;
    transition: opacity 0.3s;
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip_on .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip_on:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tool-div {
    width: 180px;
    height: 45px;
}

#tooltip {
    position: absolute;
    text-align: center;
    width: 40px;
    height: auto;
    padding: 10px;
    background-color: white;
    pointer-events: none;
}

#tooltip.hidden {
    display: none;
}

#tooltip p {
    margin: 0;
    font-family: sans-serif;
    font-size: 16px;
}