.logindes {
    text-align: right;
    padding: 0 12px;
}

.logindes h4 a {
    font-size: var(--font14);
    font-weight: var(--bold);

    padding-bottom: 5px;
    color: var(--text);
}

.logindes h6 a {
    font-size: var(--font11);
    font-weight: var(--light);

    color: var(--text);
}

.logout {
    padding-left: 16px;
    display: flex;
}

.logout[title-new]:hover:before {
    background: #ece2ff;
    border: 1px solid #102250;
    border: 1px solid var(--primary);
    border-radius: 4px;
    color: #102250;
    color: var(--primary);
    content: attr(title-new);
    display: block;
    font-family: sans-serif;
    font-size: 12px;
    font-weight: 500;
    max-width: 100px;
    position: absolute;
    right: 3px;
    text-align: center;
    text-decoration: none;
    top: 0;
    transition: all .3s linear;
    width: 4%;
    z-index: 10;
}

.logout img {
    width: 30px;
}

.notify-block img {
    width: 18px;
}

.notify-block {
    border-right: 1px solid #ccc;
    padding-right: 12px;
}

.loginico img {
    width: 32px;
}

.userwrap {
    display: flex;
}

.header-logo {
    width: 50px;
    margin: 0 auto;
}

.header-logo img {
    width: 100%;
}

.hovertip {
    position: relative;
}

.hovertip:before {
    background: #ece2ff;
    border-radius: 4px;
    color: var(--primary);
    border: 1px solid var(--primary);
    content: attr(data-tip);
    font-size: 14px;

    font-weight: 400;
    padding: 6px 10px;
    width: max-content;
    position: absolute;
    z-index: 10;
    left: -310%;
    bottom: 56px;
    font-family: sans-serif;
    transition: all 0.3s linear;
}

.hovertip:hover:before {
    left: 5px;
}

.hovertip:before {
    left: -310%;
}

.loading .loader_overlay {
    display: none;
}

.loader_overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #ffffff;
    z-index: 998;
    display: block;
    overflow: hidden;
}

.page_loader {
    height: 100%;
    margin-top: 20%;
    position: relative;
    display: grid;
    justify-items: center;
    color: var(--primary) !important;
}

#inchange {
    top: 79%;
}

#adchange {
    top: 47%;
}

.topheader-left {
    display: flex;
}

.topheader,
.topheader-right {
    display: flex;
}

#topheader_text {
    padding-right: 7px;
}

.topheader-left h4 {
    font-size: 20px;
    font-weight: normal;
}

.topheader {
    justify-content: space-between;
    align-items: baseline;
    padding: 0 5px;
}

.topheader-left h3 {
    font-weight: 700;
    color: var(--primary);
}

.topheader .toolnav ul li {
    display: inline-block;
    font-size: var(--font14);
    font-weight: var(--light);

    padding: 10px 15px 0 0;
}

.topheader .toolnav ul li span {
    color: var(--primary);
    font-weight: var(--bold);
    margin-right: 5px;
}


.breadCrumb {
    display: inline !important;
    margin-right: 5px !important;
    cursor: default !important;
}

.breadCrumbItem {
    margin-right: 5px;
    display: inline;
    color: #0084f4 !important;

    &:hover {
        color: #0084f4 !important;
        text-decoration: underline !important;
        text-decoration-thickness: 1px !important;
    }
}

.popupsecond {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background: rgba(0, 0, 0, 66%);
    --bs-modal-width: 1160px;

    .sectitle {
        font-size: 20px;

        color: var(--primary);
        font-weight: var(--bold);
        margin-bottom: 25px;
    }

    btn-close {
        box-sizing: content-box;
        padding: 0;
        color: var(--primary);
        background: none;
        border: 0;
        border-radius: 0;
        opacity: 1;
        font-size: 30px;
        font-weight: 300;
        position: absolute;
        right: 20px;
        top: 0px;
        z-index: 999;
        font-family: 'Barlow', sans-serif;
    }

    .btnwrap {
        border-top: 1px solid #d5d5d5;
        padding: 27px 0 0;
        margin: 30px 0 0;
    }
}

.popupDownload {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background: rgba(0, 0, 0, 66%);
    --bs-modal-width: 1160px;

    .modal-content {
        max-width: 600px;
        min-width: 600px;
        overflow-y: hidden;
        margin: auto;
        //padding: 20px; 
        border-radius: 23px;
    }

    .sectitle {
        font-size: 20px;

        color: var(--primary);
        font-weight: var(--bold);
        margin-bottom: 25px;
    }

    btn-close {
        box-sizing: content-box;
        padding: 0;
        color: var(--primary);
        background: none;
        border: 0;
        border-radius: 0;
        opacity: 1;
        font-size: 30px;
        font-weight: 300;
        position: absolute;
        right: 20px;
        top: 0px;
        z-index: 999;
        font-family: 'Barlow', sans-serif;
    }

    .btnwrap {
        border-top: 1px solid #d5d5d5;
        padding: 27px 0 0;
        margin: 30px 0 0;
    }
}

.modal-body h4 {
    text-align: left;
    margin-bottom: 10px !important;
    font-size: 14px;
    font-weight: 600;
    color: var(--primary);
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    width: 100%;
}

.modify {
    font-size: 18px !important;
}

.pagination_place {
    overflow-y: auto;
    position: absolute;
    inset: auto auto 0px 0px;
    margin: 0px;
    transform: translate(0px, -32px);
}

#react-select-4-input {
    height: 15px;
}

.addMemberInput {
    width: 30vh;

    .dropdown-menu {
        min-width: 20rem;
    }

    .dropdown-menu::-webkit-scrollbar {
        display: none;
    }
}

.addOSList {
    width: 30vh;

    .dropdown-menu {
        min-width: 11rem;
        overflow-y: auto;
        height: 200px;
    }
}

.dropdown-item {
    margin-left: -6px;
}

.dropdown-item:hover {
    color: #1e2125;
    background-color: #e9ecef;
}

.notFocus {
    .form-control:focus {
        background-color: var(--lightbg);
        ;
    }
}

.tabClickStyle {
    box-shadow: 0 0 0 0.2rem rgba(49, 132, 253, 0.28) !important;
    outline: 0 !important;
}

.tabInde .dropdown-container a[tabIndex="0"]:focus {
    border: 2px solid black;
}

.tabInde .dropdown-container {
    visibility: visible;
    /* shows sub-menu */
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
}

.mouseClickStyle {
    box-shadow: 0 0 0 0.2rem rgb(49 132 253 / 0%) !important;
    outline: 0 !important;
}

.UARbtn-close {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    padding: 0;
    color: var(--primary);
    background-color: #d9d9d9;
    border: none;
    border-radius: 50%;
    font-size: 2.3rem;
    font-weight: 200 !important;
    position: absolute;
    top: 0;
    right: -5%;
    width: 41px;
    height: 41px;
    z-index: 1100;
    font-family: 'Barlow', sans-serif;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 480px) {
    .topheader-left h3 {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .topheader {
        flex-direction: column-reverse;
    }

    .topheader-right {
        width: 100%;
        justify-content: flex-end;
        margin-bottom: 20px;
    }

    .topheader-left {
        width: 100%;
    }
}

/* Media query for screens up to 1087px */
@media (max-width: 1087px) {
    .UARbtn-close {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: content-box;
        padding: 0;
        color: var(--primary);
        background-color: #d9d9d9;
        border: none;
        border-radius: 50%;
        font-size: 2.0rem;
        font-weight: 200 !important;
        position: absolute;
        top: 0;
        right: -6%;
        width: 38px;
        height: 38px;
        z-index: 1100;
        font-family: 'Barlow', sans-serif;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
}