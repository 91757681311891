:root {
    --primary: #102250;
    --secondary: #A6CE38;
    --white: #fff;
    --black: #000;
    --text: #2d2b3e;
    --dark: #030303;
    --lightbg: #F2F4F2 !important;
    --border: #D6D9DC;
    --primary-active: #18347C;
    --primary-checked: #777d8f;
    --primary-disabled: #727fa1;

    --primary-light: #FFFFFF;
    --secondary-dark: #4C6700;
    --secondary-light: #102250;
    --success: #7DBB00;

    --page-title-font-color: var(--primary);
    --page-title-font-size: 14px;
    --content-title-color: #2d2b3e;
    --content-title-font: 13px;
    --content-title-font-weight: bold;
    --content-color: #2d2b3e;
    --content-size: 13px;
    --button-size: 14px;
    --button-bg-color: var(--primary);
    --button-text-color: #FFFFFF;
    --button-hover-bg-color: #FFFFFF;
    --button-hover-text-color: var(--primary);


    --gray1: #919191;
    --gray2: #9D9D9D;
    --gray3: #808080;
    --gray4: #D5D5D5;
    --gray5: #EAEAEA;
    --gray7: #606060;
    --gray8: #e8e8e8;



    --blue: #147AD6;
    --blue-light: #79D2DE;
    --pink: #EC6666;
    --purple: #AF8BEB;
    --yellow: #E9D96D;
    --orange: #FCBB4B;



    --h1: 72px;

    --font60: 60px;
    --font25: 25px;
    --font24: 24px;
    --font22: 22px;
    --font20: 20px;
    --font18: 18px;
    --font17: 17px;
    --font16: 16px;
    --font15: 15px;
    --font14: 14px;
    --font13: 13px;
    --font12: 12px;
    --font11: 11px;

    --thin: 100;
    --extralight: 200;
    --light: 300;
    --regular: 400;
    --medium: 500;
    --semibold: 600;
    --bold: 700;
    --extrabold: 800;
    --black900: 900;

    --body-font: 'Barlow';
}